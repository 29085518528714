import { createStore } from 'vuex';

// Menus
import main from './main';
import sideMenu from './side-menu';
import simpleMenu from './simple-menu';
import topMenu from './top-menu';

// IAM
import company from './iam/company.module';
import users from './iam/users.module';
import groups from './iam/groups.module';
import policies from './iam/policies.module';
import permissions from './iam/actions.module';

// Reports
import forms from './reports/forms.module';
import reports from './reports/reports.module';
import fields from './reports/fields.module';

const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    users,
    forms,
    company,
    reports,
    fields,
    groups,
    policies,
    permissions
  }
});

export function useStore() {
  return store;
}

export default store;
