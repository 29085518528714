import usersService from '@/services/iam/users.service';

const state = {
  user: null,
  users: [],
  me: null,
  clients: [],

  createUserRunning: false,
  retrieveUserRunning: false,
  listUsersRunning: false,
  deleteUserRunning: false,
  updateUserRunning: false,
  getMeRunning: false,
  listClientsRunning: false
};

const getters = {};

const actions = {
  listUsers: async ({ commit }, args) => {
    try {
      let results = usersService.list(args);
      commit('listUsersRunning', true);
      results = await results;
      commit('listUsersSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  retrieveUser: async ({ commit }, args) => {
    try {
      let result = usersService.retrieve(args);
      commit('retrieveUserRunning', true);
      result = await result;
      commit('retrieveUserSuccess', result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  createUser: async ({ commit }, args) => {
    try {
      commit('createUserRunning', true);
      await usersService.create(args);
      commit('createUserRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  updateUser: async ({ commit }, args) => {
    try {
      commit('updateUserRunning', true);
      await usersService.update(args.uuid, args);
      commit('updateUserRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  deleteUser: async ({ commit }, args) => {
    try {
      commit('deleteUserRunning', true);
      await usersService.delete(args);
      commit('deleteUserRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  getMe: async ({ commit }) => {
    try {
      let results = usersService.getMe();
      commit('getMeRunning', true);
      results = await results;
      commit('getMeSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  listClients: async ({ commit }, args) => {
    try {
      let results = usersService.listClients(args);
      commit('listClientsRunning', true);
      results = await results;
      commit('listClientsSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  updatePassword: async ({ commit }, args) => {
    try {
      commit('updatePasswordRunning', true);
      await usersService.updatePassword(args);
      commit('updatePasswordRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  }
};

const mutations = {
  listUsersRunning(state, bool = false) {
    state.listUsersRunning = bool;
  },

  listUsersSuccess(state, result) {
    state.users = result.data;
    state.listUsersRunning = false;
  },

  retrieveUserRunning(state, bool = false) {
    state.retrieveUserRunning = bool;
  },

  retrieveUserSuccess(state, result) {
    state.user = result.data;
    state.retrieveUserRunning = false;
  },

  createUserRunning(state, bool = false) {
    state.createUserRunning = bool;
  },

  updateUserRunning(state, bool = false) {
    state.updateUserRunning = bool;
  },

  deleteUserRunning(state, bool = false) {
    state.deleteUserRunning = bool;
  },

  getMeRunning(state, bool = false) {
    state.getMeRunning = bool;
  },

  getMeSuccess(state, result) {
    state.me = result.data;
    state.getMeRunning = false;
  },

  listClientsRunning(state, bool = false) {
    state.listClientsRunning = bool;
  },

  listClientsSuccess(state, result) {
    state.clients = result.data;
    state.listClientsRunning = false;
  },

  updatePasswordRunning(state, bool = false) {
    state.updatePasswordRunning = bool;
  }
};

const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default users;
export { users };
