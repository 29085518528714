import ApiService from '../_api.service';

const defaultPath = '/v1/auth/';

const authService = {
  login: function(args) {
    return ApiService.post(`${defaultPath}login`, args);
  },
  register: function(args) {
    return ApiService.post(`${defaultPath}register`, args);
  }
};

export default authService;
export { authService };
