<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8" v-if="me?.superuser">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Estatísticas</h2>
            <button
              @click.prevent="updateAll()"
              class="btn ml-auto flex items-center text-theme-1 dark:text-white"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Atualizar Tudo
            </button>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <router-link :to="{ name: 'reports' }">
                <div class="report-box zoom-in">
                  <div class="box p-5">
                    <div class="flex">
                      <ClipboardIcon class="report-box__icon text-theme-10" />
                    </div>
                    <div class="text-3xl font-medium leading-8 mt-6">
                      {{ reports.length }}
                    </div>
                    <div class="text-base text-gray-600 mt-1">Relatórios</div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <router-link :to="{ name: 'companies' }">
                <div class="report-box zoom-in">
                  <div class="box p-5">
                    <div class="flex">
                      <!--<MonitorIcon class="report-box__icon text-theme-12" />-->
                      <i
                        class="far fa-building text-theme-10"
                        style="font-size: 26px"
                      ></i>
                    </div>
                    <div class="text-3xl font-medium leading-8 mt-6">
                      {{ companies.length }}
                    </div>
                    <div class="text-base text-gray-600 mt-1">Empresas</div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <router-link :to="{ name: 'users' }">
                <div class="report-box zoom-in">
                  <div class="box p-5">
                    <div class="flex">
                      <UsersIcon class="report-box__icon text-theme-10" />
                    </div>
                    <div class="text-3xl font-medium leading-8 mt-6">
                      {{ users.length }}
                    </div>
                    <div class="text-base text-gray-600 mt-1">Usuários</div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <router-link :to="{ name: 'forms' }">
                <div class="report-box zoom-in">
                  <div class="box p-5">
                    <div class="flex">
                      <FileTextIcon class="report-box__icon text-theme-10" />
                    </div>
                    <div class="text-3xl font-medium leading-8 mt-6">
                      {{ forms.length }}
                    </div>
                    <div class="text-base text-gray-600 mt-1">Formulários</div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Reports -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              <router-link
                :to="{ name: 'reports' }"
                tag="a"
                class="intro-x flex items-center pl-5 pt-4"
              >
                Relatórios ({{ reports.length }})
                <span class="ml-2 text-theme-10 text-sm">Ver todos</span>
              </router-link>
            </h2>
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
              <button
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                  mr-2
                "
              >
                <PlusIcon class="hidden sm:block w-4 h-4 mr-2" />
                Criar Relatório
              </button>
              <button
                @click.prevent="handleUpdateReports()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                "
              >
                <RefreshCcwIcon class="hidden sm:block w-4 h-4 mr-2" />
                Atualizar
              </button>
            </div>
          </div>
          <div v-if="listReportsRunning" class="w-10 h-10">
            <LoadingIcon icon="puff" class="w-10 h-10" />
          </div>
          <div
            v-else
            class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
          >
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">ID</th>
                  <th class="whitespace-nowrap">CLIENTE</th>
                  <th class="whitespace-nowrap">FORMULÁRIO</th>
                  <th class="whitespace-nowrap">RELATOR</th>
                  <th class="text-center whitespace-nowrap">AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="report in queriedDataReports"
                  :key="report._id"
                  class="intro-x"
                >
                  <td class="w-20">
                    <router-link
                      :to="{
                        name: 'View Report',
                        params: { id: report.id }
                      }"
                      class="font-medium whitespace-nowrap"
                      >{{ report.id
                      }}<span v-if="report.appends.length > 0"
                        >[<span
                          v-for="(append, indexAppend) in report.appends"
                          :key="append.id"
                          ><span v-if="indexAppend != 0">.</span
                          >{{ append.id }}</span
                        >]</span
                      ></router-link
                    >
                  </td>
                  <td class="w-60">
                    <div class="w-60 font-medium truncate">
                      {{
                        report.signature_client && report.signature_client.user
                          ? report.signature_client.user.name
                          : report.company.name
                      }}
                    </div>
                  </td>
                  <td class="w-40">
                    <div class="w-40 text-gray-600 text-xs truncate mt-0.5">
                      {{ report.form ? report.form.name : null }}
                    </div>
                  </td>
                  <td class="w-24">
                    <div class="w-24 font-medium text-xs truncate mt-0.5">
                      {{ report.reporter.name }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <div
                        v-if="report.signed"
                        class="
                          text-center
                          btn-primary
                          sign-badge
                          py-1
                          px-2
                          mr-1
                        "
                      >
                        Assinado
                      </div>
                      <router-link
                        :to="{
                          name: 'View Report',
                          params: { id: report.id }
                        }"
                        class="flex items-center mr-3 text-theme-10"
                      >
                        <SearchIcon class="w-5 h-5 mr-1" /> Abrir
                      </router-link>
                      <router-link
                        v-if="!report.signed"
                        :to="{ name: 'report', params: { id: report._id } }"
                        class="flex items-center mr-3 text-theme-11"
                      >
                        <EditIcon class="w-5 h-5 mr-1" /> Editar
                      </router-link>
                      <button
                        class="flex items-center text-theme-6"
                        href=""
                        v-if="
                          !report.signed ||
                            me.group._id == '612a081bb0f2acd1fb0643dd'
                        "
                        @click="deleteReportHandle(report._id)"
                      >
                        <Trash2Icon class="w-5 h-5 mr-1" /> Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="text-center text-lg p-5"
              v-if="queriedDataReports.length == 0"
            >
              No Data
            </div>
            <div
              class="
              intro-y
              flex flex-wrap
              sm:flex-row sm:flex-nowrap
              items-center
              mt-3
            "
            >
              <ul class="pagination">
                <li v-if="searchArgsReports.page > 2">
                  <span
                    class="pagination__link"
                    @click="gotoPageReports('first')"
                  >
                    <ChevronsLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="searchArgsReports.page > 1">
                  <span
                    class="pagination__link"
                    @click="gotoPageReports('back')"
                  >
                    <ChevronLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <div v-for="(page, index) in pagesArrayReports" :key="index">
                  <li>
                    <span
                      @click="gotoPageReports(page)"
                      class="pagination__link"
                      :class="{
                        'pagination__link--active':
                          page == searchArgsReports.page
                      }"
                      >{{ page }}</span
                    >
                  </li>
                </div>
                <li v-if="qntdPageReports > searchArgsReports.page">
                  <span
                    class="pagination__link"
                    @click="gotoPageReports('next')"
                  >
                    <ChevronRightIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="qntdPageReports > searchArgsReports.page + 1">
                  <span
                    class="pagination__link"
                    @click="gotoPageReports('last')"
                  >
                    <ChevronsRightIcon class="w-4 h-4" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- END: Reports -->
        <!-- BEGIN: Companies -->
        <div class="col-span-12 mt-6" v-if="me?.superuser">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              <router-link
                :to="{ name: 'companies' }"
                tag="a"
                class="intro-x flex items-center pl-5 pt-4"
              >
                Empresas ({{ companies.length }})
                <span class="ml-2 text-theme-10 text-sm">Ver todos</span>
              </router-link>
            </h2>
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
              <div v-show="listCompaniesRunning" class="mr-2 w-8 h-8">
                <LoadingIcon icon="puff" class="w-8 h-8" />
              </div>
              <button
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                  mr-2
                "
              >
                <PlusIcon class="hidden sm:block w-4 h-4 mr-2" />
                Criar Empresa
              </button>
              <button
                @click.prevent="handleUpdateCompanies()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                "
              >
                <RefreshCcwIcon class="hidden sm:block w-4 h-4 mr-2" />
                Atualizar
              </button>
            </div>
          </div>
          <div v-if="listCompaniesRunning" class="w-10 h-10">
            <LoadingIcon icon="puff" class="w-10 h-10" />
          </div>
          <div
            v-else
            class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
          >
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">LOGO</th>
                  <th class="whitespace-nowrap">NOME</th>
                  <th class="whitespace-nowrap">ENDEREÇO</th>
                  <th class="text-center whitespace-nowrap">AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="company in queriedDataCompanies"
                  :key="company._id"
                  class="intro-x"
                >
                  <td class="w-40 h-20">
                    <router-link
                      :to="{ name: 'Company', params: { id: company._id } }"
                      class="font-medium whitespace-nowrap"
                    >
                      <Tippy
                        tag="img"
                        alt="Company Logo"
                        class="rounded-full h-20"
                        :src="company.logo"
                        :content="`${company.name}`"
                      />
                    </router-link>
                  </td>
                  <td class="w-52">
                    <div class="w-52 font-medium truncate">
                      {{ company.name }}
                    </div>
                  </td>
                  <td class="w-60">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ company.address }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <a class="flex items-center mr-3 text-theme-11" href="">
                        <EditIcon class="w-5 h-5 mr-1" /> Editar
                      </a>
                      <button
                        class="flex items-center text-theme-6"
                        @click="handleDelete(company._id)"
                      >
                        <Trash2Icon class="w-5 h-5 mr-1" /> Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="text-center text-lg p-5"
              v-if="queriedDataCompanies.length == 0"
            >
              No Data
            </div>
            <div
              class="
              intro-y
              flex flex-wrap
              sm:flex-row sm:flex-nowrap
              items-center
              mt-3
            "
            >
              <ul class="pagination">
                <li v-if="searchArgsCompanies.page > 2">
                  <span
                    class="pagination__link"
                    @click="gotoPageCompanies('first')"
                  >
                    <ChevronsLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="searchArgsCompanies.page > 1">
                  <span
                    class="pagination__link"
                    @click="gotoPageCompanies('back')"
                  >
                    <ChevronLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <div v-for="(page, index) in pagesArrayCompanies" :key="index">
                  <li>
                    <span
                      @click="gotoPageCompanies(page)"
                      class="pagination__link"
                      :class="{
                        'pagination__link--active':
                          page == searchArgsCompanies.page
                      }"
                      >{{ page }}</span
                    >
                  </li>
                </div>
                <li v-if="qntdPageCompanies > searchArgsCompanies.page">
                  <span
                    class="pagination__link"
                    @click="gotoPageCompanies('next')"
                  >
                    <ChevronRightIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="qntdPageCompanies > searchArgsCompanies.page + 1">
                  <span
                    class="pagination__link"
                    @click="gotoPageCompanies('last')"
                  >
                    <ChevronsRightIcon class="w-4 h-4" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- END: Companies -->
        <!-- BEGIN: Users -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              <router-link
                :to="{ name: 'users' }"
                tag="a"
                class="intro-x flex items-center pl-5 pt-4"
              >
                Usuários e Clientes ({{ users.length }})
                <span class="ml-2 text-theme-10 text-sm">Ver todos</span>
              </router-link>
            </h2>
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
              <div v-show="listUsersRunning" class="mr-2 w-8 h-8">
                <LoadingIcon icon="puff" class="w-8 h-8" />
              </div>
              <button
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                  mr-2
                "
              >
                <PlusIcon class="hidden sm:block w-4 h-4 mr-2" />
                Criar Usuário
              </button>
              <button
                @click="handleUpdateUsers()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                "
              >
                <RefreshCcwIcon class="hidden sm:block w-4 h-4 mr-2" />
                Atualizar
              </button>
            </div>
          </div>
          <div v-if="listUsersRunning" class="w-10 h-10">
            <LoadingIcon icon="puff" class="w-10 h-10" />
          </div>
          <div
            v-else
            class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
          >
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">USUARIO</th>
                  <th class="whitespace-nowrap">NOME</th>
                  <th class="whitespace-nowrap">EMPRESA</th>
                  <th class="whitespace-nowrap">EMAIL</th>
                  <th class="text-center whitespace-nowrap">AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user in queriedDataUsers"
                  :key="user._id"
                  class="intro-x"
                >
                  <td class="w-20">
                    <router-link
                      :to="{ name: 'user', params: { id: user._id } }"
                      class="font-medium whitespace-nowrap"
                      >{{ user.username }}</router-link
                    >
                  </td>
                  <td class="w-60">
                    <router-link
                      :to="{ name: 'user', params: { id: user._id } }"
                      class="font-medium whitespace-nowrap"
                      >{{ user.name }}</router-link
                    >
                  </td>
                  <td class="w-60">
                    <Tippy
                      href="javascript:;"
                      class="tooltip"
                      :content="allCompanies(user.company)"
                    >
                      <p class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                        {{ renderCompanies(user.company) }}
                      </p>
                    </Tippy>
                  </td>
                  <td class="w-40">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ user.group.name }}
                    </div>
                  </td>
                  <td class="w-60">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ user.email[0] }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <router-link
                        :to="{ name: 'user', params: { id: user._id } }"
                        class="flex items-center mr-3 text-theme-11"
                      >
                        <EditIcon class="w-5 h-5 mr-1" /> Editar
                      </router-link>
                      <div
                        class="flex items-center text-theme-6 cursor-pointer"
                        @click="handleDeleteUser(user._id)"
                      >
                        <Trash2Icon class="w-5 h-5 mr-1" /> Excluir
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="text-center text-lg p-5"
              v-if="queriedDataUsers.length == 0"
            >
              No Data
            </div>
            <div
              class="
              intro-y
              flex flex-wrap
              sm:flex-row sm:flex-nowrap
              items-center
              mt-3
            "
            >
              <ul class="pagination">
                <li v-if="searchArgsUsers.page > 2">
                  <span
                    class="pagination__link"
                    @click="gotoPageUsers('first')"
                  >
                    <ChevronsLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="searchArgsUsers.page > 1">
                  <span class="pagination__link" @click="gotoPageUsers('back')">
                    <ChevronLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <div v-for="(page, index) in pagesArrayUsers" :key="index">
                  <li>
                    <span
                      @click="gotoPageUsers(page)"
                      class="pagination__link"
                      :class="{
                        'pagination__link--active': page == searchArgsUsers.page
                      }"
                      >{{ page }}</span
                    >
                  </li>
                </div>
                <li v-if="qntdPageUsers > searchArgsUsers.page">
                  <span class="pagination__link" @click="gotoPageUsers('next')">
                    <ChevronRightIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="qntdPageUsers > searchArgsUsers.page + 1">
                  <span class="pagination__link" @click="gotoPageUsers('last')">
                    <ChevronsRightIcon class="w-4 h-4" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- END: Users -->
        <!-- BEGIN: Forms -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              <router-link
                :to="{ name: 'forms' }"
                tag="a"
                class="intro-x flex items-center pl-5 pt-4"
              >
                Formulários ({{ forms.length }})
                <span class="ml-2 text-theme-10 text-sm">Ver todos</span>
              </router-link>
            </h2>
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
              <div v-show="listFormsRunning" class="mr-2 w-8 h-8">
                <LoadingIcon icon="puff" class="w-8 h-8" />
              </div>
              <button
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                  mr-2
                "
              >
                <PlusIcon class="hidden sm:block w-4 h-4 mr-2" />
                Criar Formulário
              </button>
              <button
                @click.prevent="handleUpdateForms()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                "
              >
                <RefreshCcwIcon class="hidden sm:block w-4 h-4 mr-2" />
                Atualizar
              </button>
            </div>
          </div>
          <div v-if="listFormsRunning" class="w-10 h-10">
            <LoadingIcon icon="puff" class="w-10 h-10" />
          </div>
          <div
            v-else
            class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
          >
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">NOME</th>
                  <th class="whitespace-nowrap">EMPRESA</th>
                  <th class="whitespace-nowrap">ATIVO</th>
                  <th class="text-center whitespace-nowrap">AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="form in queriedDataForms"
                  :key="form._id"
                  class="intro-x"
                >
                  <td>
                    <a href="" class="font-medium whitespace-nowrap">{{
                      form.name
                    }}</a>
                  </td>
                  <td class="w-60">
                    <div class="w-60 text-gray-600 text-xs truncate mt-0.5">
                      {{ form.company.name }}
                    </div>
                  </td>
                  <td class="w-20">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      <CheckIcon v-if="form.active" class="text-theme-10" />
                      <XIcon class="text-theme-6" v-else />
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <a class="flex items-center mr-3 text-theme-10" href="">
                        <CopyIcon class="w-5 h-5 mr-1" /> Duplicar
                      </a>
                      <a class="flex items-center mr-3 text-theme-11" href="">
                        <EditIcon class="w-5 h-5 mr-1" /> Editar
                      </a>
                      <a class="flex items-center text-theme-6" href="">
                        <Trash2Icon class="w-5 h-5 mr-1" /> Excluir
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="text-center text-lg p-5"
              v-if="queriedDataForms.length == 0"
            >
              No Data
            </div>
            <div
              class="
              intro-y
              flex flex-wrap
              sm:flex-row sm:flex-nowrap
              items-center
              mt-3
            "
            >
              <ul class="pagination">
                <li v-if="searchArgsForms.page > 2">
                  <span
                    class="pagination__link"
                    @click="gotoPageForms('first')"
                  >
                    <ChevronsLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="searchArgsForms.page > 1">
                  <span class="pagination__link" @click="gotoPageForms('back')">
                    <ChevronLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <div v-for="(page, index) in pagesArrayForms" :key="index">
                  <li>
                    <span
                      @click="gotoPageForms(page)"
                      class="pagination__link"
                      :class="{
                        'pagination__link--active': page == searchArgsForms.page
                      }"
                      >{{ page }}</span
                    >
                  </li>
                </div>
                <li v-if="qntdPageForms > searchArgsForms.page">
                  <span class="pagination__link" @click="gotoPageForms('next')">
                    <ChevronRightIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="qntdPageForms > searchArgsForms.page + 1">
                  <span class="pagination__link" @click="gotoPageForms('last')">
                    <ChevronsRightIcon class="w-4 h-4" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- END: Forms -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, provide } from 'vue';
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';

export default defineComponent({
  data() {
    return {
      searchArgsForms: {
        page: 1,
        page_size: 10,
        name: ''
      },
      oldSearchArgsForms: {
        page: 1,
        page_size: 10,
        name: ''
      },
      searchArgsUsers: {
        page: 1,
        page_size: 10,
        name: ''
      },
      oldSearchArgsUsers: {
        page: 1,
        page_size: 10,
        name: ''
      },
      searchArgsCompanies: {
        page: 1,
        page_size: 10,
        name: ''
      },
      oldSearchArgsCompanies: {
        page: 1,
        page_size: 10,
        name: ''
      },
      searchArgsReports: {
        page: 1,
        page_size: 10,
        name: ''
      },
      oldSearchArgsReports: {
        page: 1,
        page_size: 10,
        name: ''
      }
    };
  },
  setup() {
    const salesReportFilter = ref();
    const importantNotesRef = ref();

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el = importantNotesRef.value;
      el.tns.goTo('prev');
    };
    const nextImportantNotes = () => {
      const el = importantNotesRef.value;
      el.tns.goTo('next');
    };

    return {
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes
    };
  },
  async created() {
    this.listReports(this.searchArgsReports);
    this.listCompanies(this.searchArgsCompanies);
    this.listUsers(this.searchArgsUsers);
    this.listForms(this.searchArgsForms);
  },
  computed: {
    ...mapState({
      forms: state => state.forms.forms,
      users: state => state.users.users,
      companies: state => state.company.companies,
      reports: state => state.reports.reports,
      listUsersRunning: state => state.users.listUsersRunning,
      listReportsRunning: state => state.reports.listReportsRunning,
      listCompaniesRunning: state => state.company.listCompaniesRunning,
      listFormsRunning: state => state.forms.listFormsRunning,
      me: state => state.users.me
    }),
    queriedDataForms() {
      if (this.forms && this.forms.results && this.forms.results.length > 0) {
        const results = this.forms.results;
        results.forEach(r => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    queriedDataUsers() {
      if (this.users && this.users.results && this.users.results.length > 0) {
        const results = this.users.results;
        results.forEach(r => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    queriedDataCompanies() {
      if (
        this.companies &&
        this.companies.results &&
        this.companies.results.length > 0
      ) {
        const results = this.companies.results;
        results.forEach(r => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    queriedDataReports() {
      if (
        this.reports &&
        this.reports.results &&
        this.reports.results.length > 0
      ) {
        const results = this.reports.results;
        results.forEach(r => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    qntdPageReports() {
      if (this.reports && this.reports.length) {
        const length = this.reports.length;
        return Math.ceil(length / this.searchArgsReports.page_size);
      } else return 0;
    },
    pagesArrayReports() {
      if (this.reports && this.reports.length) {
        const array = [];
        for (
          let i = this.searchArgsReports.page - 1;
          i <=
          (this.qntdPageReports > 3
            ? this.searchArgsReports.page + 1 > this.qntdPageReports
              ? this.qntdPageReports
              : this.searchArgsReports.page == 1 && this.qntdPageReports > 3
              ? 3
              : this.searchArgsReports.page + 1
            : this.qntdPageReports);
          i++
        ) {
          if (i > 0) array.push(i);
        }
        return array;
      } else return [];
    },
    qntdPageCompanies() {
      if (this.companies && this.companies.length) {
        const length = this.companies.length;
        return Math.ceil(length / this.searchArgsCompanies.page_size);
      } else return 0;
    },
    pagesArrayCompanies() {
      if (this.companies && this.companies.length) {
        const array = [];
        for (
          let i = this.searchArgsCompanies.page - 1;
          i <=
          (this.qntdPageCompanies > 3
            ? this.searchArgsCompanies.page + 1 > this.qntdPageCompanies
              ? this.qntdPageCompanies
              : this.searchArgsCompanies.page == 1 && this.qntdPageCompanies > 3
              ? 3
              : this.searchArgsCompanies.page + 1
            : this.qntdPageCompanies);
          i++
        ) {
          if (i > 0) array.push(i);
        }
        return array;
      } else return [];
    },
    qntdPageUsers() {
      if (this.users && this.users.length) {
        const length = this.users.length;
        return Math.ceil(length / this.searchArgsUsers.page_size);
      } else return 0;
    },
    pagesArrayUsers() {
      if (this.users && this.users.length) {
        const array = [];
        for (
          let i = this.searchArgsUsers.page - 1;
          i <=
          (this.qntdPageUsers > 3
            ? this.searchArgsUsers.page + 1 > this.qntdPageUsers
              ? this.qntdPageUsers
              : this.searchArgsUsers.page == 1 && this.qntdPageUsers > 3
              ? 3
              : this.searchArgsUsers.page + 1
            : this.qntdPageUsers);
          i++
        ) {
          if (i > 0) array.push(i);
        }
        return array;
      } else return [];
    },
    qntdPageForms() {
      if (this.forms && this.forms.length) {
        const length = this.forms.length;
        return Math.ceil(length / this.searchArgsForms.page_size);
      } else return 0;
    },
    pagesArrayForms() {
      if (this.forms && this.forms.length) {
        const array = [];
        for (
          let i = this.searchArgsForms.page - 1;
          i <=
          (this.qntdPageForms > 3
            ? this.searchArgsForms.page + 1 > this.qntdPageForms
              ? this.qntdPageForms
              : this.searchArgsForms.page == 1 && this.qntdPageForms > 3
              ? 3
              : this.searchArgsForms.page + 1
            : this.qntdPageForms);
          i++
        ) {
          if (i > 0) array.push(i);
        }
        return array;
      } else return [];
    }
  },
  methods: {
    ...mapActions('forms', {
      listForms: 'listForms'
    }),
    ...mapActions('users', {
      listUsers: 'listUsers'
    }),
    ...mapActions('company', {
      listCompanies: 'listCompanies'
    }),
    ...mapActions('reports', {
      listReports: 'listReports'
    }),
    convertDatetime(data) {
      data = new Date(data);
      return data.toLocaleString();
    },
    async handleUpdateForms() {
      await this.listForms(this.searchArgsForms);
    },
    async handleUpdateUsers() {
      await this.listUsers(this.searchArgsUsers);
    },
    async handleUpdateReports() {
      await this.listReports(this.searchArgsReports);
    },
    async handleUpdateCompanies() {
      await this.listCompanies(this.searchArgsCompanies);
    },
    updateAll() {
      this.handleUpdateForms();
      this.handleUpdateUsers();
      this.handleUpdateReports();
      this.handleUpdateCompanies();
    },
    allCompanies(companies) {
      let text = '';
      companies.forEach(company => {
        text += company.name + ', ';
      });
      text = text.substring(0, text.length - 2);
      return text;
    },
    renderCompanies(companies) {
      let list;
      let text = '';
      if (companies.length > 2) {
        list = companies.slice(0, 2);
      } else list = companies;
      list.forEach(company => {
        text += company.name + ', ';
      });
      text = text.substring(0, text.length - 2);
      if (companies.length > 2) text += ', ...';
      return text;
    },
    gotoPageReports(page) {
      if (typeof page == 'number') {
        if (this.searchArgsReports.page != page) {
          this.searchArgsReports.page = page;
        }
      } else {
        switch (page) {
          case 'first':
            this.searchArgsReports.page = 1;
            break;
          case 'back':
            this.searchArgsReports.page = this.searchArgsReports.page - 1;
            break;
          case 'next':
            this.searchArgsReports.page = this.searchArgsReports.page + 1;
            break;
          case 'last':
            this.searchArgsReports.page = this.qntdPageReports;
            break;
        }
      }
    },
    gotoPageCompanies(page) {
      if (typeof page == 'number') {
        if (this.searchArgsCompanies.page != page) {
          this.searchArgsCompanies.page = page;
        }
      } else {
        switch (page) {
          case 'first':
            this.searchArgsCompanies.page = 1;
            break;
          case 'back':
            this.searchArgsCompanies.page = this.searchArgsCompanies.page - 1;
            break;
          case 'next':
            this.searchArgsCompanies.page = this.searchArgsCompanies.page + 1;
            break;
          case 'last':
            this.searchArgsCompanies.page = this.qntdPageCompanies;
            break;
        }
      }
    },
    gotoPageUsers(page) {
      if (typeof page == 'number') {
        if (this.searchArgsUsers.page != page) {
          this.searchArgsUsers.page = page;
        }
      } else {
        switch (page) {
          case 'first':
            this.searchArgsUsers.page = 1;
            break;
          case 'back':
            this.searchArgsUsers.page = this.searchArgsUsers.page - 1;
            break;
          case 'next':
            this.searchArgsUsers.page = this.searchArgsUsers.page + 1;
            break;
          case 'last':
            this.searchArgsUsers.page = this.qntdPageUsers;
            break;
        }
      }
    },
    gotoPageForms(page) {
      if (typeof page == 'number') {
        if (this.searchArgsForms.page != page) {
          this.searchArgsForms.page = page;
        }
      } else {
        switch (page) {
          case 'first':
            this.searchArgsForms.page = 1;
            break;
          case 'back':
            this.searchArgsForms.page = this.searchArgsForms.page - 1;
            break;
          case 'next':
            this.searchArgsForms.page = this.searchArgsForms.page + 1;
            break;
          case 'last':
            this.searchArgsForms.page = this.qntdPageForms;
            break;
        }
      }
    }
  },
  watch: {
    searchArgsReports: {
      handler: _.debounce(function(val) {
        if (this.oldSearchArgsReports.name != val.name) {
          val.page = 1;
        }
        this.oldSearchArgsReports = _.cloneDeep(val);
        this.listReports(val);
      }, 300),
      deep: true
    },
    searchArgsCompanies: {
      handler: _.debounce(function(val) {
        if (this.oldSearchArgsCompanies.name != val.name) {
          val.page = 1;
        }
        this.oldSearchArgsCompanies = _.cloneDeep(val);
        this.listCompanies(val);
      }, 300),
      deep: true
    },
    searchArgsUsers: {
      handler: _.debounce(function(val) {
        if (this.oldSearchArgsUsers.name != val.name) {
          val.page = 1;
        }
        this.oldSearchArgsUsers = _.cloneDeep(val);
        this.listUsers(val);
      }, 300),
      deep: true
    },
    searchArgsForms: {
      handler: _.debounce(function(val) {
        if (this.oldSearchArgsForms.name != val.name) {
          val.page = 1;
        }
        this.oldSearchArgsForms = _.cloneDeep(val);
        this.listForms(val);
      }, 300),
      deep: true
    }
  }
});
</script>
