<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Forms -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              <div class="p-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Pesquisar"
                  v-model="searchArgs.filter"
                />
              </div>
            </h2>
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
              <a
                @click.prevent="handleCreate()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                  mr-2
                "
              >
                <PlusIcon class="hidden sm:block w-4 h-4 mr-2" />
                Criar Relatório
              </a>
              <button
                @click.prevent="handleUpdate()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                "
              >
                <RefreshCcwIcon class="hidden sm:block w-4 h-4 mr-2" />
                Atualizar
              </button>
            </div>
          </div>
          <div v-if="listReportsRunning" class="w-10 h-10">
            <LoadingIcon icon="puff" class="w-10 h-10" />
          </div>
          <div
            v-else
            class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
          >
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">ID</th>
                  <th class="whitespace-nowrap">CLIENTE</th>
                  <th class="whitespace-nowrap">FORMULÁRIO</th>
                  <th class="whitespace-nowrap">RELATOR</th>
                  <th class="text-center whitespace-nowrap">AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="report in queriedData"
                  :key="report._id"
                  class="intro-x"
                >
                  <td class="w-20">
                    <router-link
                      :to="{
                        name: 'View Report',
                        params: { id: report.id }
                      }"
                      class="font-medium whitespace-nowrap"
                      >{{ report.id
                      }}<span v-if="report.appends.length > 0"
                        >[<span
                          v-for="(append, indexAppend) in report.appends"
                          :key="append.id"
                          ><span v-if="indexAppend != 0">.</span
                          >{{ append.id }}</span
                        >]</span
                      ></router-link
                    >
                  </td>
                  <td class="w-60">
                    <div class="w-60 font-medium truncate">
                      {{
                        report.signature_client && report.signature_client.user
                          ? report.signature_client.user.name
                          : report.company.name
                      }}
                    </div>
                  </td>
                  <td class="w-40">
                    <div class="w-40 text-gray-600 text-xs truncate mt-0.5">
                      {{ report.form ? report.form.name : null }}
                    </div>
                  </td>
                  <td class="w-24">
                    <div class="w-24 font-medium text-xs truncate mt-0.5">
                      {{ report.reporter.name }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <div
                        v-if="report.signed"
                        class="
                          text-center
                          btn-primary
                          sign-badge
                          py-1
                          px-2
                          mr-1
                        "
                      >
                        Assinado
                      </div>
                      <router-link
                        :to="{
                          name: 'View Report',
                          params: { id: report.id }
                        }"
                        class="flex items-center mr-3 text-theme-10"
                      >
                        <SearchIcon class="w-5 h-5 mr-1" /> Abrir
                      </router-link>
                      <router-link
                        v-if="!report.signed"
                        :to="{ name: 'report', params: { id: report._id } }"
                        class="flex items-center mr-3 text-theme-11"
                      >
                        <EditIcon class="w-5 h-5 mr-1" /> Editar
                      </router-link>
                      <button
                        class="flex items-center text-theme-6"
                        href=""
                        v-if="!report.signed || me?.superuser"
                        @click="deleteReportHandle(report._id)"
                      >
                        <Trash2Icon class="w-5 h-5 mr-1" /> Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center text-lg p-5" v-if="queriedData.length == 0">
              No Data
            </div>
            <div
              class="
                intro-y
                flex flex-wrap
                sm:flex-row sm:flex-nowrap
                items-center
                mt-3
              "
            >
              <ul class="pagination">
                <li v-if="searchArgs.page > 2">
                  <span class="pagination__link" @click="gotoPage('first')">
                    <ChevronsLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="searchArgs.page > 1">
                  <span class="pagination__link" @click="gotoPage('back')">
                    <ChevronLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <div v-for="(page, index) in pagesArray" :key="index">
                  <li>
                    <span
                      @click="gotoPage(page)"
                      class="pagination__link"
                      :class="{
                        'pagination__link--active': page == searchArgs.page
                      }"
                      >{{ page }}</span
                    >
                  </li>
                </div>
                <li v-if="qntdPage > searchArgs.page">
                  <span class="pagination__link" @click="gotoPage('next')">
                    <ChevronRightIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="qntdPage > searchArgs.page + 1">
                  <span class="pagination__link" @click="gotoPage('last')">
                    <ChevronsRightIcon class="w-4 h-4" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- END: Forms -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import _ from 'lodash';

export default defineComponent({
  setup() {},
  data() {
    return {
      searchArgs: {
        page: 1,
        page_size: 10,
        filter: ''
      },
      oldSearchArgs: {
        page: 1,
        page_size: 10,
        filter: ''
      },
      formSelected: 0,
      formsOptions: []
    };
  },
  async created() {
    await this.listReports(this.searchArgs);
    await this.listForms({});
    if (this.forms) this.formsOptions = this.forms.results;
  },
  computed: {
    ...mapState({
      reports: state => state.reports.reports,
      listReportsRunning: state => state.reports.listReportsRunning,
      forms: state => state.forms.forms,
      me: state => state.users.me
    }),
    queriedData() {
      if (
        this.reports &&
        this.reports.results &&
        this.reports.results.length > 0
      ) {
        const results = this.reports.results;
        results.forEach(r => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    qntdPage() {
      if (this.reports && this.reports.length) {
        const length = this.reports.length;
        return Math.ceil(length / this.searchArgs.page_size);
      } else return 0;
    },
    pagesArray() {
      if (this.reports && this.reports.length) {
        const array = [];
        for (
          let i = this.searchArgs.page - 1;
          i <=
          (this.qntdPage > 3
            ? this.searchArgs.page + 1 > this.qntdPage
              ? this.qntdPage
              : this.searchArgs.page == 1 && this.qntdPage > 3
              ? 3
              : this.searchArgs.page + 1
            : this.qntdPage);
          i++
        ) {
          if (i > 0) array.push(i);
        }
        return array;
      } else return [];
    }
  },
  methods: {
    ...mapActions('reports', {
      listReports: 'listReports',
      deleteReport: 'deleteReport'
    }),
    ...mapActions('forms', {
      listForms: 'listForms'
    }),
    convertDatetime(data) {
      data = new Date(data);
      return data.toLocaleString();
    },
    async handleUpdate() {
      await this.listReports(this.searchArgs);
    },
    handleCreate() {
      this.$router.push({ name: 'report', params: { id: 'create' } });
    },
    async deleteReportHandle(uuid) {
      const result = await this.deleteReport(uuid);
      if (result) {
        createToast('Ocorreu algum erro a anexar o relatório', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Relatório excluido com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.listReports(this.searchArgs);
      }
    },
    gotoPage(page) {
      if (typeof page == 'number') {
        if (this.searchArgs.page != page) {
          this.searchArgs.page = page;
          this.listReports(this.searchArgs);
        }
      } else {
        switch (page) {
          case 'first':
            this.searchArgs.page = 1;
            break;
          case 'back':
            this.searchArgs.page = this.searchArgs.page - 1;
            break;
          case 'next':
            this.searchArgs.page = this.searchArgs.page + 1;
            break;
          case 'last':
            this.searchArgs.page = this.qntdPage;
            break;
        }
      }
    }
  },
  watch: {
    searchArgs: {
      handler: _.debounce(function(val) {
        if (this.oldSearchArgs.filter != val.filter) {
          val.page = 1;
        }
        this.oldSearchArgs = _.cloneDeep(val);
        this.listReports(val);
      }, 300),
      deep: true
    }
  }
});
</script>

<style scoped>
.sign-badge {
  border-radius: 0.5em;
}
</style>
