<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5" v-if="!retrieveUserRunning">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div
            @click.prevent="back()"
            class="text-theme-11 text-sm cursor-pointer"
          >
            <ChevronLeftIcon /> Voltar
          </div>
          <div class="flex row align-center justify-between">
            <div class="text-lg font-medium mt-3">
              Editando do Usuário: {{ user.username }}
            </div>
            <div class="text-right intro-x" v-show="checkChanges()">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="cancel()"
              >
                Descartar
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="save()"
              >
                Salvar
              </button>
            </div>
          </div>

          <div class="mt-5">
            <label for="company-name" class="form-label">Nome</label>
            <input
              v-model="userCopy.name"
              id="company-name"
              type="text"
              class="form-control"
            />
          </div>
          <div class="mt-3">
            <label for="company-username" class="form-label"
              >Nome de Usuário</label
            >
            <input
              v-model="userCopy.username"
              id="company-username"
              type="text"
              class="form-control"
            />
          </div>
          <div class="mt-3">
            <label for="company-emails" class="form-label">Emails</label
            ><button
              type="button"
              class="btn btn-primary btn-sm ml-2"
              @click.prevent="addMail()"
            >
              Adicionar email
            </button>
            <div v-for="(mail, index) in mailList" :key="index">
              <input
                v-model="mailList[index]"
                :id="'company-emails-' + index"
                type="text"
                class="form-control"
                :class="{ 'mt-2': index > 0 }"
              />
            </div>
            <div class="form-help">
              Para remover email basta apenas deixar a caixa de texto vazia
            </div>
          </div>
          <div class="mt-3">
            <label for="company-group" class="form-label">Grupo</label>
            <TomSelect v-model="groupList" id="company-group" multiple disabled>
              <option
                v-for="group in groupOptions"
                :key="group._id"
                :value="group._id"
              >
                {{ group.name }}
              </option>
            </TomSelect>
            <div class="form-help">
              Para editar o grupo de usuário vá a página de grupos
            </div>
          </div>
          <div class="mt-3">
            <label>Empresa *</label>
            <div class="mt-2">
              <TomSelect
                v-model="companyList"
                :options="{
                  placeholder: 'Selecione uma empresa'
                }"
                multiple
              >
                <option
                  v-for="company in companyOptions"
                  :key="company._id"
                  :value="company._id"
                >
                  {{ company.name }}
                </option>
              </TomSelect>
            </div>
          </div>
          <div class="mt-5 text-right">
            <p class="text-gray-600 text-xs">
              <span>Created At: {{ convertDatetime(userCopy.createdAt) }}</span>
              <span class="ml-2"
                >Updated At: {{ convertDatetime(userCopy.updatedAt) }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <div
        id="notification-back-content"
        class="toastify-content hidden flex flex-col sm:flex-row"
      >
        <div class="font-medium">
          Existe mudanças não salvas, salve-as ou descarte-as antes de voltar!
        </div>
      </div>
    </div>
    <div v-else class="w-8 h-8 mt-5">
      <LoadingIcon icon="puff" class="w-8 h-8" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import { createToast } from 'mosha-vue-toastify';
import Toastify from 'toastify-js';

export default {
  setup() {},
  data() {
    return {
      uuid: this.$route.params.id,
      userCopy: {
        company: [],
        createdAt: '',
        updatedAt: '',
        email: [],
        group: [],
        name: '',
        username: '',
        _id: ''
      },
      mailList: [],
      companyOptions: [],
      companyList: [],
      userCompanyOriginalList: [],
      groupList: [],
      groupOptions: []
    };
  },
  async created() {
    await this.retrieveUser(this.uuid);
    if (this.user) {
      this.userCopy = _.cloneDeep(this.user);
      this.mailList = _.cloneDeep(this.user.email);
      await this.listCompanies({});
      this.companyOptions = this.companies.results;
      this.userCopy.company.forEach(company => {
        this.companyList.push(company._id);
        this.userCompanyOriginalList.push(company._id);
      });
      this.userCopy.group.forEach(group => {
        this.groupList.push(group._id);
      });
      this.groupOptions = this.user.group;
    }
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
      retrieveUserRunning: state => state.users.retrieveUserRunning,
      companies: state => state.company.companies,
      listCompaniesRunning: state => state.company.listCompaniesRunning
    })
  },
  methods: {
    ...mapActions('users', {
      retrieveUser: 'retrieveUser',
      updateUser: 'updateUser'
    }),
    ...mapActions('company', {
      listCompanies: 'listCompanies'
    }),
    addMail() {
      this.mailList.push('');
    },
    convertDatetime(data) {
      data = new Date(data);
      return data.toLocaleString();
    },
    cancel() {
      this.userCopy = _.cloneDeep(this.user);
      this.mailList = _.cloneDeep(this.user.email);
      this.companyOptions = this.companies.results;
      this.companyList = [];
      this.userCompanyOriginalList = [];
      this.userCopy.company.forEach(company => {
        this.companyList.push(company._id);
        this.userCompanyOriginalList.push(company._id);
      });
    },
    async save() {
      if (this.checkChanges()) {
        console.log(this.companyList);
        console.log(this.user.company);
        const mails = this.mailList.filter(e => e);
        const companies = this.companyList.filter(e => e);

        const result = await this.updateUser({
          uuid: this.user._id,
          name: this.userCopy.name,
          username: this.userCopy.username,
          email: mails,
          company: companies
        });
        if (result == 403) {
          createToast('Você não tem autorização para esse editar usuário', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else if (result) {
          createToast('Ocorreu algum erro ao editar usuário', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else {
          createToast('Usuário editado com sucesso', {
            type: 'success',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
          this.$router.push({ name: 'users' });
        }
      }
    },
    checkChanges() {
      if (
        JSON.stringify(this.userCopy) != JSON.stringify(this.user) ||
        JSON.stringify(this.mailList) != JSON.stringify(this.user.email) ||
        JSON.stringify(this.companyList) !=
          JSON.stringify(this.userCompanyOriginalList)
      ) {
        return true;
      } else return false;
    },
    back() {
      if (this.checkChanges()) {
        this.NotificationBackToggle();
      } else {
        this.$router.push({ name: 'users' });
      }
    },
    NotificationBackToggle() {
      Toastify({
        node: cash('#notification-back-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast();
    }
  }
};
</script>

<style>
.placeholderButtons {
  height: 38px;
}
</style>
