import policiesService from '@/services/iam/policies.service';

const state = {
  policy: null,
  policies: [],

  createPolicyRunning: false,
  retrievePolicyRunning: false,
  listPoliciesRunning: false,
  deletePolicyRunning: false,
  updatePolicyRunning: false,
  attachActionRunning: false,
  detachActionRunning: false,
  listUsersOfPolicyRunning: false
};

const getters = {};

const actions = {
  listPolicies: async ({ commit }, args) => {
    try {
      let results = policiesService.list(args);
      commit('listPoliciesRunning', true);
      results = await results;
      commit('listPoliciesSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  retrievePolicy: async ({ commit }, args) => {
    try {
      let result = policiesService.retrieve(args);
      commit('retrievePolicyRunning', true);
      result = await result;
      commit('retrievePolicySuccess', result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  createPolicy: async ({ commit }, args) => {
    try {
      commit('createPolicyRunning', true);
      await policiesService.create(args);
      commit('createPolicyRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  updatePolicy: async ({ commit }, args) => {
    try {
      commit('updatePolicyRunning', true);
      await policiesService.update(args.uuid, args);
      commit('updatePolicyRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  deletePolicy: async ({ commit }, args) => {
    try {
      commit('deletePolicyRunning', true);
      await policiesService.delete(args);
      commit('deletePolicyRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  attachAction: async ({ commit }, args) => {
    try {
      commit('attachActionRunning', true);
      await policiesService.attachAction(args.uuid, args);
      commit('attachActionRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  detachAction: async ({ commit }, args) => {
    try {
      commit('detachActionRunning', true);
      await policiesService.detachAction(args.uuid, args);
      commit('detachActionRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  }
};

const mutations = {
  listPoliciesRunning(state, bool = false) {
    state.listPoliciesRunning = bool;
  },

  listPoliciesSuccess(state, result) {
    state.policies = result.data;
    state.listPoliciesRunning = false;
  },

  retrievePolicyRunning(state, bool = false) {
    state.retrievePolicyRunning = bool;
  },

  retrievePolicySuccess(state, result) {
    state.policy = result.data;
    state.retrievePolicyRunning = false;
  },

  createPolicyRunning(state, bool = false) {
    state.createPolicyRunning = bool;
  },

  updatePolicyRunning(state, bool = false) {
    state.updatePolicyRunning = bool;
  },

  deletePolicyRunning(state, bool = false) {
    state.deletePolicyRunning = bool;
  },

  attachActionRunning(state, bool = false) {
    state.attachActionRunning = bool;
  },

  detachActionRunning(state, bool = false) {
    state.detachActionRunning = bool;
  }
};

const policies = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default policies;
export { policies };
