<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <div v-if="loading" class="w-8 h-8">
          <LoadingIcon icon="puff" class="w-8 h-8" />
        </div>
        <!-- BEGIN: Wizard Layout -->
        <div class="intro-y box py-10 sm:py-20 mt-5" v-else>
          <div
            class="
              wizard
              flex flex-col
              lg:flex-row
              justify-center
              px-5
              sm:px-20
            "
          >
            <div
              class="
                intro-x
                lg:text-center
                flex
                items-center
                lg:block
                flex-1
                z-10
              "
            >
              <button
                @click.prevent="goToPage(0)"
                class="w-10 h-10 rounded-full btn"
                :class="{
                  'btn-primary': wizard[0],
                  'bg-gray-200 dark:bg-dark-1': !wizard[0]
                }"
              >
                1
              </button>
              <div
                class="lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto"
              >
                Informações Básicas
              </div>
            </div>
            <div
              class="
                intro-x
                lg:text-center
                flex
                items-center
                mt-5
                lg:mt-0 lg:block
                flex-1
                z-10
              "
            >
              <button
                @click.prevent="goToPage(1)"
                class="w-10 h-10 rounded-full btn"
                :class="{
                  'btn-primary': wizard[1],
                  'text-gray-600 bg-gray-200 dark:bg-dark-1': !wizard[1]
                }"
              >
                2
              </button>
              <div
                class="
                  lg:w-32
                  text-base
                  lg:mt-3
                  ml-3
                  lg:mx-auto
                  text-gray-700
                  dark:text-gray-600
                "
              >
                <span v-if="creating">Construção do Relatório</span>
                <span v-else>Editar Relatório</span>
              </div>
            </div>
            <div
              class="
                intro-x
                lg:text-center
                flex
                items-center
                mt-5
                lg:mt-0 lg:block
                flex-1
                z-10
              "
            >
              <button
                @click.prevent="goToPage(2)"
                class="w-10 h-10 rounded-full btn"
                :class="{
                  'btn-primary': wizard[2],
                  'text-gray-600 bg-gray-200 dark:bg-dark-1': !wizard[2]
                }"
              >
                3
              </button>
              <div
                class="
                  lg:w-32
                  text-base
                  lg:mt-3
                  ml-3
                  lg:mx-auto
                  text-gray-700
                  dark:text-gray-600
                "
              >
                <span v-if="creating">Confirmar Criação</span>
                <span v-else>Confirmar Edição</span>
              </div>
            </div>
            <div
              class="
                wizard__line
                hidden
                lg:block
                w-2/3
                bg-gray-200
                dark:bg-dark-1
                absolute
                mt-5
              "
            ></div>
          </div>

          <!-- WIZARD 1 -->
          <div class="intro-y box p-5" id="wizard-1" v-show="wizard[0]">
            <div class="mt-3">
              <label>Formulário *</label>
              <div class="mt-2">
                <TomSelect
                  v-model="reports.form"
                  :options="{
                    placeholder: 'Selecione uma empresa'
                  }"
                  :disabled="!this.creating"
                >
                  <option
                    v-for="form in formsOptions"
                    :key="form._id"
                    :value="form._id"
                  >
                    {{ form.name }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <div class="mt-3">
              <label>Empresa *</label>
              <div class="mt-2">
                <TomSelect
                  v-model="reports.company"
                  :options="{
                    placeholder: 'Selecione uma empresa'
                  }"
                  :disabled="!this.creating"
                >
                  <option
                    v-for="company in companyOptions"
                    :key="company._id"
                    :value="company._id"
                  >
                    {{ company.name }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <div class="text-right mt-5">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="cancel()"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="nextPage()"
              >
                Próxima
              </button>
            </div>
          </div>

          <!-- WIZARD 2 -->
          <div
            class="intro-y box p-5"
            id="wizard-2"
            v-show="wizard[1]"
            v-if="formSelected"
          >
            <div class="mt-3">
              <h1 class="text-4xl font-medium text-center">
                {{ formSelected.name }}
              </h1>
              <h2 class="text-3xl font-medium text-center">
                {{ formSelected.company?.name }}
              </h2>
            </div>
            <div v-for="(e, index) in formSelected.structure" :key="index">
              <div v-if="e.type == 'field'">
                <label :for="'field_' + e.name.replace(/\s/g, '')"
                  >{{ e.name }} <span v-if="!e.null">*</span></label
                >
                <div
                  v-if="e.field_type == 'checkbox'"
                  class="flex flex-row w-100 mb-2"
                >
                  <div
                    class="form-check mr-2"
                    v-for="(option, indexOption) in e.options"
                    :key="indexOption"
                  >
                    <input
                      v-model="content[index][0].data"
                      :id="
                        'field_' + e.name.replace(/\s/g, '') + '_' + indexOption
                      "
                      class="form-check-input"
                      type="checkbox"
                      :value="option.name"
                    />
                    <label
                      class="form-check-label"
                      :for="
                        'field_' + e.name.replace(/\s/g, '') + '_' + indexOption
                      "
                      >{{ option.name }}</label
                    >
                  </div>
                </div>
                <div v-else-if="e.field_type == 'select'" class="min-body">
                  <TomSelect
                    v-model="content[index][0].data"
                    :options="{
                      placeholder: 'Selecione uma empresa'
                    }"
                  >
                    <option
                      v-for="(option, indexOption) in e.options"
                      :key="indexOption"
                      :value="option.name"
                    >
                      {{ option.name }}
                    </option>
                  </TomSelect>
                </div>
                <div
                  v-else-if="e.field_type == 'radio'"
                  class="flex flex-row w-100 mx-2 mb-2"
                >
                  <div
                    class="form-check mr-2"
                    v-for="(option, indexOption) in e.options"
                    :key="indexOption"
                  >
                    <input
                      v-model="content[index][0].data"
                      :id="
                        'field_' + e.name.replace(/\s/g, '') + '_' + indexOption
                      "
                      class="form-check-input"
                      type="radio"
                      :name="
                        'field_' + e.name.replace(/\s/g, '') + '_' + indexOption
                      "
                      :value="option.name"
                    />
                    <label
                      class="form-check-label"
                      :for="
                        'field_' + e.name.replace(/\s/g, '') + '_' + indexOption
                      "
                      >{{ option.name }}</label
                    >
                  </div>
                </div>
                <div v-else-if="e.field_type == 'textarea'">
                  <textarea
                    v-model="content[index][0].data"
                    class="form-control mb-2"
                    :required="!e.null"
                    :id="'field_' + e.name.replace(/\s/g, '')"
                  ></textarea>
                </div>
                <div v-else-if="e.field_type == 'tel'">
                  <input
                    v-model="content[index][0].data"
                    type="tel"
                    pattern="([0-9]{2}) [0-9]{5}-[0-9]{4}"
                    class="form-control mb-2"
                    :required="!e.null"
                    :id="'field_' + e.name.replace(/\s/g, '')"
                  />
                </div>
                <div v-else-if="e.field_type == 'image'">
                  <div :id="'image_' + index">
                    <Dropzone
                      ref-key="dropzoneSingleRef"
                      :options="{
                        url:
                          'https://api-rattecnico.bushdogsolutions.com/v1/media/',
                        thumbnailWidth: 150,
                        maxFilesize: 5120,
                        acceptedFiles: 'image/*',
                        paramName: 'media',
                        headers: { Authorization: 'Bearer ' + token }
                      }"
                      class="dropzone"
                    >
                      <div class="text-lg font-medium">
                        Arraste e solte uma imagem aqui ou clique para
                        selecionar
                      </div>
                      <div class="text-gray-600">
                        Tamanho Máximo de Arquivo: 5MB
                      </div>
                    </Dropzone>
                  </div>
                </div>
                <div v-else-if="e.field_type == 'graph'">
                  Gráfico é renderizado após criação do relatório
                </div>
                <div v-else-if="e.field_type == 'fixedtext'">
                  Texto fixo é visualizado quando relatório for criado
                </div>
                <div v-else-if="e.field_type == 'datetime-local'">
                  <DatePicker
                    :dark="darkMode"
                    locale="pt-BR"
                    format="dd/MM/yyyy HH:mm"
                    v-model="content[index][0].data"
                  ></DatePicker>
                </div>
                <div v-else>
                  <input
                    v-model="content[index][0].data"
                    :type="e.field_type"
                    class="form-control mb-2"
                    :required="!e.null"
                    :id="'field_' + e.name.replace(/\s/g, '')"
                  />
                </div>
              </div>
              <div v-if="e.type == 'section'">
                <h2 class="text-3xl font-medium text-left mb-2">
                  {{ e.name }}
                </h2>
                <div class="full-line mb-3"></div>
                <div
                  class="
                    flex flex-row flex-wrap
                    mb-2
                    w-100
                    align-center
                    justify-around
                  "
                >
                  <div
                    v-for="(r, indexRepeats) in e.repeats"
                    :key="indexRepeats"
                    class="
                      form-group
                      flex-wrap
                      form-inline
                      flex flex-row
                      align-center
                      justify-around
                    "
                    :class="{ 'force-width-100': e.fields.length > 1 }"
                  >
                    <div
                      v-for="(f, indexFields) in e.fields"
                      :key="indexFields"
                    >
                      <label
                        :for="
                          'section_' +
                            e.name.replace(/\s/g, '') +
                            '_field_' +
                            f.name.replace(/\s/g, '') +
                            '_' +
                            indexRepeats
                        "
                        >{{ f.name + ' ' + (indexRepeats + 1) }}
                        <span v-if="!f.null">*</span></label
                      >
                      <div
                        v-if="f.type == 'checkbox'"
                        class="flex flex-row w-100 mb-2"
                      >
                        <div
                          class="form-check mr-2"
                          v-for="(option, indexOption) in f.options"
                          :key="indexOption"
                        >
                          <input
                            v-model="
                              content[index][indexRepeats][indexFields].data
                            "
                            :id="
                              'section_' +
                                e.name.replace(/\s/g, '') +
                                '_field_' +
                                f.name.replace(/\s/g, '') +
                                '_' +
                                indexRepeats +
                                '_' +
                                indexOption
                            "
                            class="form-check-input"
                            type="checkbox"
                            :value="option.name"
                          />
                          <label
                            class="form-check-label"
                            :for="
                              'section_' +
                                e.name.replace(/\s/g, '') +
                                '_field_' +
                                f.name.replace(/\s/g, '') +
                                '_' +
                                indexRepeats +
                                '_' +
                                indexOption
                            "
                            >{{ option.name }}</label
                          >
                        </div>
                      </div>
                      <div v-else-if="f.type == 'select'" class="min-body">
                        <TomSelect
                          v-model="
                            content[index][indexRepeats][indexFields].data
                          "
                          :options="{
                            placeholder: 'Selecione uma empresa'
                          }"
                        >
                          <option
                            v-for="(option, indexOption) in f.options"
                            :key="indexOption"
                            :value="option.name"
                          >
                            {{ option.name }}
                          </option>
                        </TomSelect>
                      </div>
                      <div
                        v-else-if="f.type == 'radio'"
                        class="flex flex-row w-100 mx-2 mb-2"
                      >
                        <div
                          class="form-check mr-2"
                          v-for="(option, indexOption) in f.options"
                          :key="indexOption"
                        >
                          <input
                            :id="
                              'section_' +
                                e.name.replace(/\s/g, '') +
                                '_field_' +
                                f.name.replace(/\s/g, '') +
                                '_' +
                                indexRepeats +
                                '_' +
                                indexOption
                            "
                            class="form-check-input"
                            type="radio"
                            :name="
                              'section_' +
                                e.name.replace(/\s/g, '') +
                                '_field_' +
                                f.name.replace(/\s/g, '') +
                                '_' +
                                indexRepeats +
                                '_' +
                                indexOption
                            "
                            v-model="
                              content[index][indexRepeats][indexFields].data
                            "
                            :value="option.name"
                          />
                          <label
                            class="form-check-label"
                            :for="
                              'section_' +
                                e.name.replace(/\s/g, '') +
                                '_field_' +
                                f.name.replace(/\s/g, '') +
                                '_' +
                                indexRepeats +
                                '_' +
                                indexOption
                            "
                            >{{ option.name }}</label
                          >
                        </div>
                      </div>
                      <div v-else-if="f.type == 'textarea'">
                        <textarea
                          v-model="
                            content[index][indexRepeats][indexFields].data
                          "
                          class="form-control mb-2"
                          :required="f.null"
                          :id="
                            'section_' +
                              e.name.replace(/\s/g, '') +
                              '_field_' +
                              f.name.replace(/\s/g, '') +
                              '_' +
                              indexRepeats
                          "
                        ></textarea>
                      </div>
                      <div v-else-if="f.type == 'tel'">
                        <input
                          v-model="
                            content[index][indexRepeats][indexFields].data
                          "
                          type="tel"
                          pattern="([0-9]{2}) [0-9]{5}-[0-9]{4}"
                          class="form-control mb-2"
                          :required="!f.null"
                          :id="
                            'section_' +
                              e.name.replace(/\s/g, '') +
                              '_field_' +
                              f.name.replace(/\s/g, '') +
                              '_' +
                              indexRepeats
                          "
                        />
                      </div>
                      <div v-else-if="f.type == 'image'">// TODO IMAGE</div>

                      <div v-else-if="f.type == 'datetime-local'">
                        <DatePicker
                          :dark="darkMode"
                          locale="pt-BR"
                          format="dd/MM/yyyy HH:mm"
                          v-model="
                            content[index][indexRepeats][indexFields].data
                          "
                        ></DatePicker>
                      </div>
                      <div v-else>
                        <input
                          v-model="
                            content[index][indexRepeats][indexFields].data
                          "
                          :type="f.type"
                          class="form-control mb-2"
                          :required="!f.null"
                          :id="
                            'section_' +
                              e.name.replace(/\s/g, '') +
                              '_field_' +
                              f.name.replace(/\s/g, '') +
                              '_' +
                              indexRepeats
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right mt-5">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="previousPage()"
              >
                Voltar
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="nextPage()"
              >
                Próxima
              </button>
            </div>
          </div>
          <!-- WIZARD 2 -->
          <div class="intro-y box p-5" id="wizard-3" v-show="wizard[2]">
            <div class="my-5 py-5">
              <h2 class="text-lg font-medium text-center">
                <span v-if="creating"
                  >Você deseja confirmar a criação do relatório?</span
                >
                <span v-else>Você deseja confirmar a edição do relatório?</span>
              </h2>
            </div>
            <div class="text-right mt-5">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="previousPage()"
              >
                Voltar
              </button>
              <span v-if="creating">
                <button
                  type="button"
                  v-if="!createReportRunning"
                  class="btn btn-primary w-24"
                  @click.prevent="create()"
                >
                  Criar
                </button>
                <button type="button" v-else class="btn btn-primary w-24">
                  <LoadingIcon icon="puff" class="w-4 h-4 text-primary" /> Criar
                </button>
              </span>
              <span v-else>
                <button
                  type="button"
                  class="btn btn-primary w-24"
                  @click.prevent="save()"
                >
                  Salvar
                </button>
              </span>
            </div>
          </div>
        </div>
        <!-- END: Wizard Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, provide } from 'vue';
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import TokenService from '@/services/storage.service';
import 'mosha-vue-toastify/dist/style.css';

export default defineComponent({
  setup() {
    const dropzoneSingleRef = ref();

    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el;
    });

    return {
      dropzoneSingleRef
    };
  },
  data() {
    return {
      param: this.$route.params.id,
      creating: false,
      loading: true,
      wizard: [true, false, false],
      page: 0,
      reports: {
        form: 0,
        company: 0
      },
      companyOptions: [],
      formSelected: {},
      test: [],
      content: [],
      sections: [],
      token: '',
      darkMode: false
    };
  },
  async created() {
    this.darkMode = TokenService.getDarkMode() === 'true';
    this.token = TokenService.getToken();
    const resultCompanies = await this.listCompanies({});
    if (resultCompanies) console.log(resultCompanies);
    // error
    else {
      this.companyOptions = this.companies.results;
      this.companyOptions.unshift({ _id: 0, name: 'Nenhuma' });
    }
    const result = await this.listForms({ limit: 50 });
    if (result) console.log(result); // error
    if (this.param == 'create') {
      this.creating = true;
    } else {
      this.creating = false;
      const resultRetrieve = await this.retrieveReport(this.param);
      if (result) console.log(resultRetrieve);
      if (this.report) {
        this.reports.form = this.report.form ? this.report.form._id : 0;
        this.reports.company = this.report.company._id;
        this.constructReport();
        this.content = this.report.data;
        this.sections = this.report.sections;
        this.nextPage();
      }
    }
    this.loading = false;
  },
  computed: {
    ...mapState({
      forms: state => state.forms.forms,
      report: state => state.reports.report,
      companies: state => state.company.companies,
      createReportRunning: state => state.reports.createReportRunning
    }),
    formsOptions() {
      const formList = this.forms.results.filter(e => e.active == true);
      formList.unshift({ _id: 0, name: 'Nenhum' });
      return formList;
    }
  },
  methods: {
    ...mapActions('forms', {
      listForms: 'listForms',
      retrieveForm: 'retrieveForm'
    }),
    ...mapActions('company', {
      listCompanies: 'listCompanies'
    }),
    ...mapActions('reports', {
      createReport: 'createReport',
      retrieveReport: 'retrieveReport',
      updateReport: 'updateReport'
    }),
    nextPage() {
      if (this.validate(this.page)) {
        this.wizard[this.page] = false;
        this.wizard[this.page + 1] = true;
        this.page++;
      } else {
        createToast('Alguns campos obrigatórios não foram preenchidos', {
          type: 'warning',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      }
    },
    previousPage() {
      this.wizard[this.page] = false;
      this.wizard[this.page - 1] = true;
      this.page--;
    },
    goToPage(wantedPage) {
      if (wantedPage > this.page) {
        if (this.validate(this.page)) {
          this.wizard[this.page] = false;
          this.wizard[wantedPage] = true;
          this.page = wantedPage;
        } else {
          createToast('Alguns campos obrigatórios não foram preenchidos', {
            type: 'warning',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        }
      } else {
        this.wizard[this.page] = false;
        this.wizard[wantedPage] = true;
        this.page = wantedPage;
      }
    },
    validate(page) {
      switch (page) {
        case 0: {
          if (this.creating) {
            if (this.reports.form != 0 && this.reports.company != 0) {
              this.content = [];
              this.constructReport();
              return true;
            } else return false;
          } else {
            return true;
          }
        }
        case 1: {
          return true;
        }
        case 2:
          return true;
        default:
          return false;
      }
    },
    constructReport() {
      this.formSelected = this.formsOptions.find(
        e => e._id == this.reports.form
      );
      this.formSelected.structure.forEach(e => {
        this.sections.push({ name: e.name, type: e.type });
        if (e.type == 'field') {
          if (e.field_type == 'select') {
            e.options.unshift({ _id: 0, name: 'Nenhuma' });
          }
          if (e.field_type == 'checkbox' || e.field_type == 'image') {
            this.content.push([{ name: e.name, type: e.field_type, data: [] }]);
          } else {
            this.content.push([{ name: e.name, type: e.field_type, data: '' }]);
          }
        } else if (e.type == 'section') {
          let arrayIntern = [];
          const arrayRepeats = [];
          for (let i = 0; i < e.repeats; i++) {
            arrayIntern = [];
            e.fields.forEach(f => {
              if (f.type == 'select') {
                f.options.unshift({ _id: 0, name: 'Nenhuma' });
              }
              if (f.type == 'checkbox' || e.field_type == 'image') {
                const obj = { name: f.name, type: f.type, data: [] };
                arrayIntern.push(obj);
              } else {
                const obj = { name: f.name, type: f.type, data: '' };
                arrayIntern.push(obj);
              }
            });
            arrayRepeats.push(arrayIntern);
          }
          this.content.push(arrayRepeats);
        }
      });
      return true;
    },
    async create() {
      const result = await this.createReport({
        form: this.reports.form,
        data: this.content,
        sections: this.sections,
        company: this.reports.company
      });
      if (result == 207) {
        createToast(
          'Relatório criado com sucesso, porem gráfico pode estar sendo gerado ainda',
          {
            type: 'warning',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          }
        );
        this.$router.push({ name: 'reports' });
      } else if (result) {
        createToast('Ocorreu algum erro ao criar o relatório', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Relatório criado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.$router.push({ name: 'reports' });
      }
    },
    async save() {
      const result = await this.updateReport({
        uuid: this.param,
        data: this.content,
        sections: this.sections
      });
      if (result) {
        createToast('Ocorreu algum erro ao editar o relatório', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Relatório editado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.$router.push({ name: 'reports' });
      }
    },
    cancel() {
      this.$router.push({ name: 'reports' });
    },
    onSuccess(evt, image) {
      const imageId = evt.previewElement.parentElement.parentElement.id;
      console.log(imageId);
      const index = imageId.split('_')[1];
      this.content[index][0].data.push(image.data.url);
      console.log(this.content);
    }
  },
  watch: {
    dropzoneSingleRef: {
      handler() {
        if (this.dropzoneSingleRef && this.dropzoneSingleRef.value) {
          this.dropzoneSingleRef.value.dropzone.on('success', this.onSuccess);
        }
      },
      deep: true
    }
  }
});
</script>

<style scoped>
.full-line {
  width: 100%;
  border-bottom: solid 1px rgba(100, 100, 100, 0.5);
}
input[type='text'],
input[type='number'],
input[type='datetime-local'],
input[type='tel'],
input[type='email'] {
  min-width: 350px;
}
.min-body {
  width: 350px;
}
.force-width-100 {
  width: 100%;
}

.dp__theme_dark {
  --dp-background-color: #232a3b;
  --dp-success-color: #00b894;
  --dp-primary-color: #0984e3;
}
.dp__theme_light {
  --dp-background-color: #f5f6fa;
  --dp-success-color: #00b894;
  --dp-primary-color: #0984e3;
}
</style>
