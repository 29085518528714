/* eslint-disable no-unused-vars */
import axios from 'axios';
import TokenService from './storage.service';

const ApiService = {
  _401interceptor: null,
  loginDefaultUrl: null,

  // eslint-disable-next-line camelcase
  mount401Interceptor(redirect_url_full) {
    this._401interceptor = axios.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (error.request.status == 401) {
          TokenService.saveLastPage(window.location.toString());
          TokenService.removeToken();

          const refreshToken = TokenService.getRefreshToken();
          const result = await this.post('/v1/auth/refresh/', {
            refresh_token: refreshToken
          });
          if (result.status == 200) {
            if (result.data.auth) {
              TokenService.saveToken(result.data.access_token);
              TokenService.saveRefreshToken(result.data.refresh_token);

              const lastPage = TokenService.getLastPage();
              window.location.href = lastPage;
            }
          } else {
            setTimeout(() => {
              // eslint-disable-next-line camelcase
              window.location.href = redirect_url_full;
            }, 200);
          }
        }

        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  },

  init(baseURL, loginDefaultUrl) {
    axios.defaults.baseURL = baseURL;
    this.loginDefaultUrl = loginDefaultUrl;
  },

  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
  },

  setHeaderBasic(token) {
    axios.defaults.headers.common.Authorization = `Basic ${token}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data, config = {}) {
    return axios.post(resource, data, config);
  },

  put(resource, data, config = {}) {
    return axios.put(resource, data);
  },

  patch(resource, data, config = {}) {
    return axios.patch(resource, data, (config = {}));
  },

  delete(resource, config) {
    return axios.delete(resource, config);
  },

  async download(url) {
    return axios({
      method: 'get',
      url: url,
      responseType: 'arraybuffer'
    });
  },

  async upload(url, file, progressCallback) {
    const result = await axios({
      method: 'post',
      url,
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'X-Requested-With',
        common: {}
      }
    });

    const formData = new FormData();

    Object.keys(result.data.fields).forEach(key => {
      formData.append(key, result.data.fields[key]);
    });
    formData.set('file', file);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        this.status === 204 ? resolve(true) : reject(this.responseText);
      };
      if (progressCallback) {
        xhr.upload.onprogress = e => {
          if (e.lengthComputable) {
            const percentComplete = (e.loaded / file.size) * 100;
            progressCallback(percentComplete);
          }
        };
      }
      xhr.open('POST', result.data.url, true);
      xhr.send(formData);
    });
  },

  redirectToLogin() {
    TokenService.removeToken();
    window.location.href = this.loginDefaultUrl;
  },

  customRequest(data) {
    return axios(data);
  }
};

export default ApiService;
export { ApiService };
