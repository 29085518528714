import actionsService from '@/services/iam/actions.service';

const state = {
  action: null,
  actions: [],

  createActionRunning: false,
  retrieveActionRunning: false,
  listActionsRunning: false,
  deleteActionRunning: false,
  updateActionRunning: false,
  listUsersOfActionRunning: false
};

const getters = {};

const actions = {
  listActions: async ({ commit }, args) => {
    try {
      let results = actionsService.list(args);
      commit('listActionsRunning', true);
      results = await results;
      commit('listActionsSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  retrieveAction: async ({ commit }, args) => {
    try {
      let result = actionsService.retrieve(args);
      commit('retrieveActionRunning', true);
      result = await result;
      commit('retrieveActionSuccess', result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  createAction: async ({ commit }, args) => {
    try {
      commit('createActionRunning', true);
      await actionsService.create(args);
      commit('createActionRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  updateAction: async ({ commit }, args) => {
    try {
      commit('updateActionRunning', true);
      await actionsService.update(args.uuid, args);
      commit('updateActionRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  deleteAction: async ({ commit }, args) => {
    try {
      commit('deleteActionRunning', true);
      await actionsService.delete(args);
      commit('deleteActionRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  }
};

const mutations = {
  listActionsRunning(state, bool = false) {
    state.listActionsRunning = bool;
  },

  listActionsSuccess(state, result) {
    state.actions = result.data;
    state.listActionsRunning = false;
  },

  retrieveActionRunning(state, bool = false) {
    state.retrieveActionRunning = bool;
  },

  retrieveActionSuccess(state, result) {
    state.action = result.data;
    state.retrieveActionRunning = false;
  },

  createActionRunning(state, bool = false) {
    state.createActionRunning = bool;
  },

  updateActionRunning(state, bool = false) {
    state.updateActionRunning = bool;
  },

  deleteActionRunning(state, bool = false) {
    state.deleteActionRunning = bool;
  }
};

const permissions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default permissions;
export { permissions };
