import ApiService from '../_api.service'

const defaultPath = '/v1/iam/company'

const companyService = {
  list: function(args) {
    let queryParams = '?'
    Object.keys(args).forEach(key => {
      if (args[key]) queryParams += key + '=' + args[key] + '&'
    })
    return ApiService.get(`${defaultPath}/${queryParams}`)
  },

  retrieve: function(uuid) {
    if (!uuid) return
    return ApiService.get(`${defaultPath}/${uuid}`)
  },

  create: function(model) {
    return ApiService.post(`${defaultPath}`, model)
  },

  update: function(uuid, model) {
    if (!uuid || !model) return
    return ApiService.patch(`${defaultPath}/${uuid}`, model)
  },

  delete: function(uuid) {
    if (!uuid) return
    return ApiService.delete(`${defaultPath}/${uuid}`)
  }
}

export default companyService
export { companyService }
