import ApiService from '../_api.service';

const defaultPath = '/v1/reports';

const reportsService = {
  list: function(args) {
    let queryParams = '?';
    Object.keys(args).forEach(key => {
      if (args[key]) queryParams += key + '=' + args[key] + '&';
    });
    return ApiService.get(`${defaultPath}/${queryParams}`);
  },

  retrieve: function(uuid) {
    if (!uuid) return;
    return ApiService.get(`${defaultPath}/${uuid}`);
  },

  create: function(model) {
    return ApiService.post(`${defaultPath}`, model);
  },

  update: function(uuid, model) {
    if (!uuid || !model) return;
    return ApiService.patch(`${defaultPath}/${uuid}`, model);
  },

  delete: function(uuid) {
    if (!uuid) return;
    return ApiService.delete(`${defaultPath}/${uuid}`);
  },

  listAppendables: function(args) {
    return ApiService.get(`${defaultPath}/appendables/${args}`);
  },

  attach: function(uuid, appends) {
    if (!uuid) return;
    return ApiService.post(`${defaultPath}/${uuid}/attach/`, {
      appends: appends
    });
  },

  detach: function(uuid, appends) {
    if (!uuid) return;
    return ApiService.post(`${defaultPath}/${uuid}/detach/`, {
      appends: appends
    });
  },

  sign: function(uuid, args) {
    if (!uuid) return;

    return ApiService.post(`${defaultPath}/${uuid}/sign/`, args);
  },

  print: function(uuid) {
    if (!uuid) return;

    return ApiService.download(`${defaultPath}/${uuid}/print/`);
  },

  reprint: function(uuid) {
    if (!uuid) return;

    return ApiService.download(`${defaultPath}/${uuid}/reprint/`);
  },

  sendMail: function(uuid) {
    if (!uuid) return;

    return ApiService.post(`${defaultPath}/${uuid}/mail/`);
  },

  listUnsigneds: function(args) {
    let queryParams = '?';
    Object.keys(args).forEach(key => {
      if (args[key]) queryParams += key + '=' + args[key] + '&';
    });
    return ApiService.get(`${defaultPath}/unsigned/${queryParams}`);
  }
};

export default reportsService;
export { reportsService };
