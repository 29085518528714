import fieldsService from '@/services/reports/fields.service'

const state = {
  field: null,
  fields: [],
  me: null,

  createFieldRunning: false,
  retrieveFieldRunning: false,
  listFieldsRunning: false,
  deleteFieldRunning: false,
  updateFieldRunning: false,
  getMeRunning: false
}

const getters = {}

const actions = {
  listFields: async ({ commit }, args) => {
    try {
      let results = fieldsService.list(args)
      commit('listFieldsRunning', true)
      results = await results
      commit('listFieldsSuccess', results.data)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  retrieveField: async ({ commit }, args) => {
    try {
      let result = fieldsService.retrieve(args)
      commit('retrieveFieldRunning', true)
      result = await result
      commit('retrieveFieldSuccess', result.data)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  createField: async ({ commit }, args) => {
    try {
      commit('createFieldRunning', true)
      await fieldsService.create(args)
      commit('createFieldRunning', false)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  updateField: async ({ commit }, args) => {
    try {
      commit('updateFieldRunning', true)
      await fieldsService.update(args.uuid, args)
      commit('updateFieldRunning', false)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  deleteField: async ({ commit }, args) => {
    try {
      commit('deleteFieldRunning', true)
      await fieldsService.delete(args)
      commit('deleteFieldRunning', false)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  }
}

const mutations = {
  listFieldsRunning(state, bool = false) {
    state.listFieldsRunning = bool
  },

  listFieldsSuccess(state, result) {
    state.fields = result.data
    state.listFieldsRunning = false
  },

  retrieveFieldRunning(state, bool = false) {
    state.retrieveFieldRunning = bool
  },

  retrieveFieldSuccess(state, result) {
    state.field = result.data
    state.retrieveFieldRunning = false
  },

  createFieldRunning(state, bool = false) {
    state.createFieldRunning = bool
  },

  updateFieldRunning(state, bool = false) {
    state.updateFieldRunning = bool
  },

  deleteFieldRunning(state, bool = false) {
    state.deleteFieldRunning = bool
  },

  getMeRunning(state, bool = false) {
    state.getMeRunning = bool
  },

  getMeSuccess(state, result) {
    state.me = result.data
    state.getMeRunning = false
  }
}

const fields = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default fields
export { fields }
