import groupsService from '@/services/iam/groups.service';

const state = {
  group: null,
  usersGroup: [],
  groups: [],

  createGroupRunning: false,
  retrieveGroupRunning: false,
  listGroupsRunning: false,
  deleteGroupRunning: false,
  updateGroupRunning: false,
  attachPolicyRunning: false,
  detachPolicyRunning: false,
  attachUserRunning: false,
  detachUserRunning: false,
  listUsersOfGroupRunning: false
};

const getters = {};

const actions = {
  listGroups: async ({ commit }, args) => {
    try {
      let results = groupsService.list(args);
      commit('listGroupsRunning', true);
      results = await results;
      commit('listGroupsSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  retrieveGroup: async ({ commit }, args) => {
    try {
      let result = groupsService.retrieve(args);
      commit('retrieveGroupRunning', true);
      result = await result;
      commit('retrieveGroupSuccess', result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  createGroup: async ({ commit }, args) => {
    try {
      commit('createGroupRunning', true);
      const result = await groupsService.create(args);
      commit('createGroupSuccess', result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  updateGroup: async ({ commit }, args) => {
    try {
      commit('updateGroupRunning', true);
      await groupsService.update(args.uuid, args);
      commit('updateGroupRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  deleteGroup: async ({ commit }, args) => {
    try {
      commit('deleteGroupRunning', true);
      await groupsService.delete(args);
      commit('deleteGroupRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  attachPolicy: async ({ commit }, args) => {
    try {
      commit('attachPolicyRunning', true);
      await groupsService.attachPolicy(args.uuid, args);
      commit('attachPolicyRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  detachPolicy: async ({ commit }, args) => {
    try {
      commit('detachPolicyRunning', true);
      await groupsService.detachPolicy(args.uuid, args);
      commit('detachPolicyRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  attachUser: async ({ commit }, args) => {
    try {
      commit('attachUserRunning', true);
      await groupsService.attachUser(args.uuid, args);
      commit('attachUserRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  detachUser: async ({ commit }, args) => {
    try {
      commit('detachUserRunning', true);
      await groupsService.detachUser(args.uuid, args);
      commit('detachUserRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  listUsersOfGroup: async ({ commit }, args) => {
    try {
      let results = groupsService.listUsers(args);
      commit('listUsersOfGroupRunning', true);
      results = await results;
      commit('listUsersOfGroupSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  }
};

const mutations = {
  listGroupsRunning(state, bool = false) {
    state.listGroupsRunning = bool;
  },

  listGroupsSuccess(state, result) {
    state.groups = result.data;
    state.listGroupsRunning = false;
  },

  retrieveGroupRunning(state, bool = false) {
    state.retrieveGroupRunning = bool;
  },

  retrieveGroupSuccess(state, result) {
    state.group = result.data;
    state.retrieveGroupRunning = false;
  },

  createGroupRunning(state, bool = false) {
    state.createGroupRunning = bool;
  },

  createGroupSuccess(state, result) {
    state.group = result.data;
    state.createGroupRunning = false;
  },

  updateGroupRunning(state, bool = false) {
    state.updateGroupRunning = bool;
  },

  deleteGroupRunning(state, bool = false) {
    state.deleteGroupRunning = bool;
  },

  attachPolicyRunning(state, bool = false) {
    state.attachPolicyRunning = bool;
  },

  detachPolicyRunning(state, bool = false) {
    state.attachPolicyRunning = bool;
  },

  attachUserRunning(state, bool = false) {
    state.attachUserRunning = bool;
  },

  detachUserRunning(state, bool = false) {
    state.detachUserRunning = bool;
  },

  listUsersOfGroupRunning(state, bool = false) {
    state.listUsersOfGroupRunning = bool;
  },

  listUsersOfGroupSuccess(state, result) {
    state.usersGroup = result.data;
    state.listUsersOfGroupRunning = false;
  }
};

const groups = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default groups;
export { groups };
