<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <label for="company-name" class="form-label">Nome da Empresa</label>
            <input
              v-model="content.name"
              id="company-name"
              type="text"
              class="form-control"
            />
          </div>
          <div class="mt-3">
            <label for="company-address" class="form-label">Endereço</label>
            <input
              v-model="content.address"
              id="company-address"
              type="text"
              class="form-control"
            />
          </div>
          <div class="mt-3">
            <label for="company-cpnj" class="form-label">CNPJ</label>
            <input
              v-model="content.doc_id"
              id="company-cpnj"
              type="text"
              class="form-control"
            />
          </div>
          <div class="mt-3">
            <label for="company-phone" class="form-label">Telefone</label>
            <input
              v-model="content.phone"
              id="company-phone"
              type="text"
              class="form-control"
            />
          </div>

          <div class="mt-3">
            <label for="company-emails" class="form-label">Emails</label
            ><button
              type="button"
              class="btn btn-primary btn-sm ml-2"
              @click.prevent="addMail()"
            >
              Adicionar email
            </button>
            <div v-for="(mail, index) in mailList" :key="index">
              <input
                v-model="mailList[index]"
                :id="'company-emails-' + index"
                type="text"
                class="form-control"
                :class="{ 'mt-2': index > 0 }"
              />
            </div>
            <div class="form-help">
              Para remover email basta apenas deixar a caixa de texto vazia
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Logotipo</label>
            <div v-if="hasImg && !changeImage">
              <img
                :src="content.logo"
                alt="Logotipo"
                style="height: 150px"
                class="mt-2"
              />
              <button
                class="btn btn-primary mt-2"
                @click="changeImage = !changeImage"
              >
                Trocar Imagem
              </button>
            </div>
            <div v-if="hasImg && changeImage">
              <button
                class="btn btn-danger mt-2"
                @click="changeImage = !changeImage"
              >
                Cancelar troca de imagem
              </button>
            </div>
            <div class="file-upload" v-if="!hasImg || changeImage">
              <Dropzone
                ref-key="dropzoneSingleRef"
                :options="{
                  url: 'https://api-rattecnico.bushdogsolutions.com/v1/media/',
                  thumbnailWidth: 150,
                  maxFilesize: 5120,
                  acceptedFiles: 'image/*',
                  paramName: 'media',
                  headers: { Authorization: 'Bearer ' + token }
                }"
                class="dropzone"
              >
                <div class="text-lg font-medium">
                  Arraste e solte uma imagem aqui ou clique para selecionar
                </div>
                <div class="text-gray-600">
                  Tamanho Máximo de Arquivo: 5MB
                </div>
              </Dropzone>
            </div>
          </div>
          <div class="text-right mt-5">
            <button type="button" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary w-24"
              @click.prevent="save()"
            >
              Save
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, provide } from 'vue';
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import TokenService from '@/services/storage.service';
import _ from 'lodash';

export default defineComponent({
  setup() {
    const categories = ref([1, 3]);
    const editorConfig = {
      toolbar: {
        items: ['bold', 'italic', 'link']
      }
    };
    const editorData = ref('<p>Content of the editor.</p>');

    const dropzoneSingleRef = ref();

    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el;
    });

    return {
      dropzoneSingleRef,
      categories,
      editorConfig,
      editorData
    };
  },
  data() {
    return {
      select: 0,
      uuid: this.$route.params.id,
      content: {
        name: '',
        address: '',
        doc_id: '',
        phone: '',
        logo: ''
      },
      hasImg: false,
      changeImage: false,
      mailList: [],
      token: ''
    };
  },
  async created() {
    this.token = TokenService.getToken();
    if (this.uuid == 'create') {
      this.creating = true;
    } else {
      this.creating = false;
      await this.retrieveCompany(this.uuid);
      console.log(this.company);
      this.mailList = _.cloneDeep(this.company.emails);
      this.content = this.company;
      if (this.content.logo) {
        this.hasImg = true;
      }
    }
  },
  computed: {
    ...mapState({
      company: state => state.company.company
    })
  },
  methods: {
    ...mapActions('company', {
      retrieveCompany: 'retrieveCompany',
      updateCompany: 'updateCompany',
      createCompany: 'createCompany'
    }),
    async save() {
      const mails = this.mailList.filter(e => e);
      if (this.creating) {
        const result = await this.createCompany({
          name: this.content.name,
          address: this.content.address,
          doc_id: this.content.doc_id,
          phone: this.content.phone,
          logo: this.content.logo,
          emails: mails
        });
        if (result) {
          createToast('Ocorreu algum erro ao criar empresa', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else {
          createToast('Empresa criada com sucesso', {
            type: 'success',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
          this.$router.push({ name: 'companies' });
        }
      } else {
        const result = await this.updateCompany({
          uuid: this.uuid,
          name: this.content.name,
          address: this.content.address,
          doc_id: this.content.doc_id,
          phone: this.content.phone,
          logo: this.content.logo,
          emails: mails
        });
        if (result) {
          createToast('Ocorreu algum erro a editar empresa', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else {
          createToast('Empresa editada com sucesso', {
            type: 'success',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
          this.$router.push({ name: 'companies' });
        }
      }
    },
    cancel() {
      this.content = this.company;
      this.mailList = _.cloneDeep(this.user.email);
    },
    addMail() {
      this.mailList.push('');
    },
    onSuccess(evt, image) {
      this.content.logo = image.data.url;
    }
  },
  watch: {
    dropzoneSingleRef: {
      handler() {
        if (this.dropzoneSingleRef && this.dropzoneSingleRef.value) {
          this.dropzoneSingleRef.value.dropzone.on('success', this.onSuccess);
        }
      },
      deep: true
    }
  }
});
</script>
