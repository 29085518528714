<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Forms -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              <div class="p-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Pesquisar"
                  v-model="searchArgs.name"
                />
              </div>
            </h2>
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
              <a
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                  mr-2
                "
                href="javascript:;"
                data-toggle="modal"
                data-target="#modal-creategroup"
              >
                <PlusIcon class="hidden sm:block w-4 h-4 mr-2" />
                Criar Grupo
              </a>
              <button
                @click.prevent="handleUpdate()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                "
              >
                <RefreshCcwIcon class="hidden sm:block w-4 h-4 mr-2" />
                Atualizar
              </button>
            </div>
          </div>
          <div v-if="listGroupsRunning" class="mt-5 w-10 h-10">
            <LoadingIcon icon="puff" class="mt-5 w-10 h-10" />
          </div>
          <div
            v-else
            class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
          >
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">NOME</th>
                  <th class="whitespace-nowrap">CREATED AT</th>
                  <th class="whitespace-nowrap">UPDATED AT</th>
                  <th class="text-center whitespace-nowrap">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="group in queriedData"
                  :key="group._id"
                  class="intro-x"
                >
                  <td class="w-60">
                    <router-link
                      :to="{ name: 'group', params: { id: group._id } }"
                      class="font-medium whitespace-nowrap"
                      >{{ group.name }}</router-link
                    >
                  </td>
                  <td class="w-30">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ group.createdAt }}
                    </div>
                  </td>
                  <td class="w-30">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ group.updatedAt }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <router-link
                        :to="{ name: 'group', params: { id: group._id } }"
                        class="flex items-center mr-3 text-theme-11"
                      >
                        <EditIcon class="w-5 h-5 mr-1" /> Editar
                      </router-link>
                      <button
                        class="flex items-center text-theme-6"
                        @click="handleDelete(group._id)"
                      >
                        <Trash2Icon class="w-5 h-5 mr-1" /> Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center text-lg p-5" v-if="queriedData.length == 0">
              No Data
            </div>
          </div>
          <div
            class="
              intro-y
              flex flex-wrap
              sm:flex-row sm:flex-nowrap
              items-center
              mt-3
            "
          >
            <ul class="pagination">
              <li v-if="searchArgs.page > 2">
                <span class="pagination__link" @click="gotoPage('first')">
                  <ChevronsLeftIcon class="w-4 h-4" />
                </span>
              </li>
              <li v-if="searchArgs.page > 1">
                <span class="pagination__link" @click="gotoPage('back')">
                  <ChevronLeftIcon class="w-4 h-4" />
                </span>
              </li>
              <div v-for="(page, index) in pagesArray" :key="index">
                <li>
                  <span
                    @click="gotoPage(page)"
                    class="pagination__link"
                    :class="{
                      'pagination__link--active': page == searchArgs.page
                    }"
                    >{{ page }}</span
                  >
                </li>
              </div>
              <li v-if="qntdPage > searchArgs.page">
                <span class="pagination__link" @click="gotoPage('next')">
                  <ChevronRightIcon class="w-4 h-4" />
                </span>
              </li>
              <li v-if="qntdPage > searchArgs.page + 1">
                <span class="pagination__link" @click="gotoPage('last')">
                  <ChevronsRightIcon class="w-4 h-4" />
                </span>
              </li>
            </ul>
          </div>
        </div>
        <!-- END: Forms -->
      </div>
    </div>
    <!-- BEGIN: Modal Content -->
    <div id="modal-creategroup" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">Criar Grupo</h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <div class="mt-5">
                <label for="group-name" class="form-label">Nome</label>
                <input
                  v-model="newGroup.name"
                  id="group-name"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              @click="cancel()"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="handleCreateGroup()"
            >
              Criar
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import _ from 'lodash';

export default defineComponent({
  setup() {},
  data() {
    return {
      searchArgs: {
        page: 1,
        page_size: 10,
        name: ''
      },
      oldSearchArgs: {
        page: 1,
        page_size: 10,
        name: ''
      },
      newGroup: {
        name: ''
      }
    };
  },
  async created() {
    await this.listGroups(this.searchArgs);
  },
  computed: {
    ...mapState({
      group: state => state.groups.group,
      groups: state => state.groups.groups,
      listGroupsRunning: state => state.groups.listGroupsRunning
    }),
    queriedData() {
      if (
        this.groups &&
        this.groups.results &&
        this.groups.results.length > 0
      ) {
        const results = this.groups.results;
        results.forEach(r => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    qntdPage() {
      if (this.groups && this.groups.length) {
        const length = this.groups.length;
        return Math.ceil(length / this.searchArgs.page_size);
      } else return 0;
    },
    pagesArray() {
      if (this.groups && this.groups.length) {
        const array = [];
        for (
          let i = this.searchArgs.page - 1;
          i <=
          (this.qntdPage > 3
            ? this.searchArgs.page + 1 > this.qntdPage
              ? this.qntdPage
              : this.searchArgs.page == 1 && this.qntdPage > 3
              ? 3
              : this.searchArgs.page + 1
            : this.qntdPage);
          i++
        ) {
          if (i > 0) array.push(i);
        }
        return array;
      } else return [];
    }
  },
  methods: {
    ...mapActions('groups', {
      listGroups: 'listGroups',
      deleteGroup: 'deleteGroup',
      createGroup: 'createGroup'
    }),
    convertDatetime(data) {
      data = new Date(data);
      return data.toLocaleString();
    },
    async handleUpdate() {
      await this.listGroups(this.searchArgs);
    },
    async handleDelete(uuid) {
      const result = await this.deleteGroup(uuid);
      if (result) {
        createToast('Ocorreu algum erro a excluir o grupo', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Grupo excluido com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.listGroups(this.searchArgs);
      }
    },
    async handleCreateGroup() {
      const result = await this.createGroup(this.newGroup);
      if (result == 403) {
        createToast('Você não tem permissão para criar esse grupo', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao criar esse grupo', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Grupo criado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        if (this.group) {
          this.$router.push({ name: 'group', params: { id: this.group._id } });
        } else this.listGroups(this.searchArgs);
      }
    },
    cancel() {
      this.newGroup = {
        name: ''
      };
    },
    gotoPage(page) {
      if (typeof page == 'number') {
        if (this.searchArgs.page != page) {
          this.searchArgs.page = page;
          this.listGroups(this.searchArgs);
        }
      } else {
        switch (page) {
          case 'first':
            this.searchArgs.page = 1;
            break;
          case 'back':
            this.searchArgs.page = this.searchArgs.page - 1;
            break;
          case 'next':
            this.searchArgs.page = this.searchArgs.page + 1;
            break;
          case 'last':
            this.searchArgs.page = this.qntdPage;
            break;
        }
      }
    }
  },
  watch: {
    searchArgs: {
      handler: _.debounce(function(val) {
        if (this.oldSearchArgs.name != val.name) {
          val.page = 1;
        }
        this.oldSearchArgs = _.cloneDeep(val);
        this.listGroups(val);
      }, 300),
      deep: true
    }
  }
});
</script>
