<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5" v-if="!retrieveGroupRunning">
      <div class="intro-y col-span-12">
        <div class="intro-y box p-5">
          <div class="flex row align-center justify-between">
            <div class="text-lg font-medium mt-3">Dados Principais</div>
            <div class="text-right intro-x" v-show="checkChanges()">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="cancel()"
              >
                Descartar
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="save()"
              >
                Salvar
              </button>
            </div>
          </div>
          <div class="mt-5">
            <label for="group-name" class="form-label">Nome</label>
            <input
              v-model="groupCopy.name"
              id="group-name"
              type="text"
              class="form-control"
            />
          </div>
          <div class="mt-3">
            <label for="group-description" class="form-label">Descrição</label>
            <ClassicEditor
              v-model="groupCopy.description"
              :config="editorConfig"
            />
          </div>
        </div>
        <div class="intro-y box p-5 mt-3 chat">
          <div class="chat__tabs nav nav-tabs gap-2" role="tablist">
            <a
              id="policies-tab"
              data-toggle="tab"
              data-target="#policies"
              href="javascript:;"
              class="py-2 px-4 rounded-md text-center active"
              role="tab"
              aria-controls="policies"
              aria-selected="true"
              >Politicas</a
            >
            <a
              id="users-tab"
              data-toggle="tab"
              data-target="#users"
              href="javascript:;"
              class="py-2 px-4 rounded-md text-center"
              role="tab"
              aria-controls="users"
              aria-selected="false"
              >Usuários</a
            >
          </div>
          <div class="tab-content mt-5">
            <div
              id="policies"
              class="tab-pane active"
              role="tabpanel"
              aria-labelledby="policies-tab"
            >
              <div class="text-right">
                <a
                  class="btn btn-primary w-36"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#modal-addpolicy"
                >
                  Adicionar Política
                </a>
              </div>
              <div
                class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
              >
                <table class="table table-report sm:mt-2">
                  <thead>
                    <tr>
                      <th class="whitespace-nowrap">NOME</th>
                      <th class="whitespace-nowrap">CREATED AT</th>
                      <th class="whitespace-nowrap">UPDATED AT</th>
                      <th class="text-center whitespace-nowrap">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="policy in queriedPolicies"
                      :key="policy._id"
                      class="intro-x"
                    >
                      <td class="w-60">
                        <div class="font-medium whitespace-nowrap">
                          {{ policy.name }}
                        </div>
                      </td>
                      <td class="w-30">
                        <div
                          class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                        >
                          {{ convertDatetime(policy.createdAt) }}
                        </div>
                      </td>
                      <td class="w-30">
                        <div
                          class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                        >
                          {{ convertDatetime(policy.updatedAt) }}
                        </div>
                      </td>
                      <td class="table-report__action w-10">
                        <div class="flex justify-center items-center">
                          <button
                            class="flex items-center text-theme-6"
                            @click="handleDetachPolicy(policy._id)"
                          >
                            <XIcon class="w-5 h-5 mr-1" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="text-center text-lg p-5"
                  v-if="queriedPolicies.length == 0"
                >
                  No Data
                </div>
              </div>
            </div>
            <div
              id="users"
              class="tab-pane"
              role="tabpanel"
              aria-labelledby="users-tab"
            >
              <div class="text-right">
                <a
                  type="button"
                  class="btn btn-primary w-36"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#modal-adduser"
                >
                  Adicionar Usuário
                </a>
              </div>
              <div
                class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
              >
                <table class="table table-report sm:mt-2">
                  <thead>
                    <tr>
                      <th class="whitespace-nowrap">USUARIO</th>
                      <th class="whitespace-nowrap">NOME</th>
                      <th class="whitespace-nowrap">EMPRESA</th>
                      <th class="whitespace-nowrap">GRUPO</th>
                      <th class="whitespace-nowrap">EMAIL</th>
                      <th class="text-center whitespace-nowrap">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="user in queriedUsers"
                      :key="user._id"
                      class="intro-x"
                    >
                      <td class="w-20">
                        <router-link
                          :to="{ name: 'user', params: { id: user._id } }"
                          class="font-medium whitespace-nowrap"
                          >{{ user.username }}</router-link
                        >
                      </td>
                      <td class="w-60">
                        <router-link
                          :to="{ name: 'user', params: { id: user._id } }"
                          class="font-medium whitespace-nowrap"
                          >{{ user.name }}</router-link
                        >
                      </td>
                      <td class="w-60">
                        <Tippy
                          href="javascript:;"
                          class="tooltip"
                          :content="allCompanies(user.company)"
                        >
                          <p
                            class="
                              text-gray-600 text-xs
                              whitespace-nowrap
                              mt-0.5
                            "
                          >
                            {{ renderCompanies(user.company) }}
                          </p>
                        </Tippy>
                      </td>
                      <td class="w-40">
                        <div
                          class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                        >
                          {{ user.group.name }}
                        </div>
                      </td>
                      <td class="w-60">
                        <div
                          class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                        >
                          {{ user.email[0] }}
                        </div>
                      </td>
                      <td class="table-report__action w-56">
                        <div class="flex justify-center items-center">
                          <button
                            class="flex items-center text-theme-6"
                            @click="handleDetachUser(user._id)"
                          >
                            <XIcon class="w-5 h-5 mr-1" />
                            <!-- TODO: REMOVE ACTION -->
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="text-center text-lg p-5"
                  v-if="queriedUsers.length == 0"
                >
                  No Data
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-8 h-8 mt-5">
      <LoadingIcon icon="puff" class="w-8 h-8" />
    </div>
    <!-- BEGIN: Modal Content -->
    <div id="modal-addpolicy" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">Adicionar Politica</h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <TomSelect
                v-model="policyToAdd"
                :options="{
                  placeholder: 'Selecione as políticas'
                }"
                multiple
              >
                <option
                  v-for="option in policiesList"
                  :key="option._id"
                  :value="option._id"
                >
                  {{ option.name }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="addPolicyToGroup()"
            >
              Anexar
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <!-- BEGIN: Modal Content -->
    <div id="modal-adduser" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">Adicionar Usuários</h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <TomSelect
                v-model="usersToAdd"
                :options="{
                  placeholder: 'Selecione os usuários'
                }"
                multiple
              >
                <option
                  v-for="option in usersList"
                  :key="option._id"
                  :value="option._id"
                >
                  {{ option.name }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="addUserToGroup()"
            >
              Anexar
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import _ from 'lodash';

export default {
  setup() {},
  data() {
    return {
      uuid: this.$route.params.id,
      groupCopy: {
        name: '',
        description: '',
        policies: [],
        _id: ''
      },
      editorConfig: {
        toolbar: {
          items: []
        }
      },
      tabSelected: 'policies',
      searchArgsUsersOnGroup: {
        page: 0,
        page_size: 10,
        group: ''
      },
      searchArgsPolicies: {
        page: 0,
        page_size: 10,
        name: ''
      },
      searchArgsUsers: {
        page: 0,
        page_size: 10,
        name: ''
      },
      policyToAdd: [],
      usersToAdd: []
    };
  },
  async created() {
    await this.retrieveGroup(this.uuid);
    this.searchArgsUsersOnGroup.group = this.uuid;
    if (this.group) {
      await this.listUsersOfGroup(this.searchArgsUsersOnGroup);
      this.groupCopy = _.cloneDeep(this.group);
      this.listPolicies(this.searchArgsPolicies);
      this.listUsers(this.searchArgsUsers);
    }
  },
  computed: {
    ...mapState({
      group: (state) => state.groups.group,
      usersGroup: (state) => state.groups.usersGroup,
      policies: (state) => state.policies.policies,
      users: (state) => state.users.users,
      retrieveGroupRunning: (state) => state.groups.retrieveGroupRunning,
      listUsersOfGroupRunning: (state) => state.groups.listUsersOfGroupRunning,
      listPoliciesRunning: (state) => state.policies.listPoliciesRunning,
      listUsersRunning: (state) => state.users.listUsersRunning
    }),
    queriedPolicies() {
      if (
        this.groupCopy &&
        this.groupCopy.policies &&
        this.groupCopy.policies.length > 0
      ) {
        return this.groupCopy.policies;
      }
      return [];
    },
    queriedUsers() {
      if (
        this.usersGroup &&
        this.usersGroup.results &&
        this.usersGroup.results.length > 0
      ) {
        const results = this.usersGroup.results;
        results.forEach((r) => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    policiesList() {
      if (
        this.policies &&
        this.policies.results &&
        this.policies.results.length > 0 &&
        this.groupCopy &&
        this.groupCopy.policies
      ) {
        let results = this.policies.results;
        results = results.filter((el) => {
          return !this.groupCopy.policies.find((rm) => {
            return rm._id === el._id;
          });
        });
        return results;
      } else return [];
    },
    usersList() {
      if (
        this.users &&
        this.users.results &&
        this.users.results.length > 0 &&
        this.usersGroup &&
        this.usersGroup.results
      ) {
        let results = this.users.results;
        results = results.filter((el) => {
          return !this.usersGroup.results.find((rm) => {
            return rm._id === el._id;
          });
        });
        return results;
      } else return [];
    }
  },
  methods: {
    ...mapActions('groups', {
      retrieveGroup: 'retrieveGroup',
      listUsersOfGroup: 'listUsersOfGroup',
      attachPolicy: 'attachPolicy',
      detachPolicy: 'detachPolicy',
      attachUser: 'attachUser',
      detachUser: 'detachUser',
      updateGroup: 'updateGroup'
    }),
    ...mapActions('policies', {
      listPolicies: 'listPolicies'
    }),
    ...mapActions('users', {
      listUsers: 'listUsers'
    }),
    changeTab(tab) {
      this.tabSelected = tab;
    },
    convertDatetime(data) {
      data = new Date(data);
      return data.toLocaleString();
    },
    renderCompanies(companies) {
      let list;
      let text = '';
      if (companies.length > 2) {
        list = companies.slice(0, 2);
      } else list = companies;
      list.forEach((company) => {
        text += company.name + ', ';
      });
      text = text.substring(0, text.length - 2);
      if (companies.length > 2) text += ', ...';
      return text;
    },
    allCompanies(companies) {
      let text = '';
      companies.forEach((company) => {
        text += company.name + ', ';
      });
      text = text.substring(0, text.length - 2);
      return text;
    },
    checkChanges() {
      if (JSON.stringify(this.groupCopy) != JSON.stringify(this.group)) {
        return true;
      } else return false;
    },
    async addPolicyToGroup() {
      if (this.policyToAdd.length > 0) {
        const result = await this.attachPolicy({
          uuid: this.groupCopy._id,
          policies: this.policyToAdd
        });
        if (result) {
          createToast('Ocorreu algum erro ao adicionar as políticas', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else {
          createToast('Políticas adicionadas com sucesso', {
            type: 'success',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
          await this.retrieveGroup(this.uuid);
          this.groupCopy = _.cloneDeep(this.group);
        }
      }
    },
    async handleDetachPolicy(policy) {
      const policies = [];
      policies.push(policy);
      const result = await this.detachPolicy({
        uuid: this.groupCopy._id,
        policies
      });
      if (result == 403) {
        createToast('Você não tem permissão para remover essa política', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao remover a política', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Política removida com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        await this.retrieveGroup(this.uuid);
        this.groupCopy = _.cloneDeep(this.group);
      }
    },
    async addUserToGroup() {
      if (this.usersToAdd.length > 0) {
        const result = await this.attachUser({
          uuid: this.groupCopy._id,
          users: this.usersToAdd
        });
        if (result == 403) {
          createToast('Você não tem permissão para adicionar esses usuários', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else if (result) {
          createToast('Ocorreu algum erro ao adicionar os usuários', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else {
          createToast('Usuários adicionados com sucesso', {
            type: 'success',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
          await this.retrieveGroup(this.uuid);
          this.groupCopy = _.cloneDeep(this.group);
          await this.listUsersOfGroup(this.searchArgsUsersOnGroup);
        }
      }
    },
    async handleDetachUser(userId) {
      const users = [];
      users.push(userId);
      const result = await this.detachUser({
        uuid: this.groupCopy._id,
        users
      });
      if (result == 403) {
        createToast('Você não tem permissão para remover esse usuário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao remover o usuário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Usuário removido com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        await this.retrieveGroup(this.uuid);
        this.groupCopy = _.cloneDeep(this.group);
        await this.listUsersOfGroup(this.searchArgsUsersOnGroup);
      }
    },
    cancel() {
      this.groupCopy = _.cloneDeep(this.group);
    },
    async save() {
      const result = await this.updateGroup(this.groupCopy);
      if (result == 403) {
        createToast('Você não tem permissão para editar esse grupo', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao editar esse grupo', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Grupo editado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        await this.retrieveGroup(this.uuid);
        this.groupCopy = _.cloneDeep(this.group);
        await this.listUsersOfGroup(this.searchArgsUsersOnGroup);
      }
    }
  }
};
</script>

<style>
.tabGroup:focus {
  text-decoration: none !important;
  outline-color: transparent !important;
}
</style>
