<template>
  <div>
    <!--<DarkModeSwitcher />-->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Logo"
              class="w-6"
              :src="require(`@/assets/images/logo.svg`).default"
            />
            <span class="text-white text-lg ml-3">
              Rat<span class="font-medium">Técnico</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt="Rattecnico"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/reports.svg`).default"
            />
            <!--
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
               <br />
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Acesse o sistema de relatórios ao lado
            </div>-->
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              "
            >
              Login
            </h2>
            <!--<div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>-->
            <div class="intro-x mt-8">
              <input
                type="text"
                name="username"
                v-model="username"
                class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                  "
                placeholder="Nome de Usuário"
              />
              <input
                type="password"
                name="password"
                v-model="password"
                class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                    mt-4
                  "
                placeholder="Senha"
              />
            </div>
            <div
              class="
                  intro-x
                  flex
                  text-gray-700
                  dark:text-gray-600
                  text-xs
                  sm:text-sm
                  mt-4
                "
            >
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Lembrar-me</label
                >
              </div>
              <a href="">Esqueceu a senha?</a>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                @click.prevent="submit()"
                class="
                    btn btn-primary
                    py-3
                    px-4
                    w-full
                    xl:w-32 xl:mr-3
                    align-top
                  "
              >
                Entrar
              </button>

              <router-link :to="{ name: 'register' }" tag="a">
                <button
                  class="
                    btn btn-outline-secondary
                    py-3
                    px-4
                    w-full
                    xl:w-32 xl:mr-3
                    mt-3
                    xl:mt-0
                    align-top
                  "
                >
                  Cadastrar-se
                </button>
              </router-link>
              <a
                href="https://rattecnico-files.s3.amazonaws.com/app/ratTecnico-1.6.1.apk"
              >
                <button
                  class="btn btn-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
                >
                  Baixar App
                </button></a
              >
            </div>
            <div class="darkmode-menu">
              <DarkModeSwitcher />
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import TokenService from '../../services/storage.service';
import AuthService from '../../services/auth/auth.service';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue';

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login');
    });
  },
  data() {
    return {
      title: 'BushDogStudios',

      redirect: this.$route.query.redirect,

      username: null,
      password: null,

      showAlert: false,
      messageAlert: null
    };
  },
  created() {
    const loggedIn = TokenService.getToken();
    if (loggedIn) {
      this.$router.push({ name: 'Home' });
    }
  },
  methods: {
    async submit() {
      const obj = {
        login: this.username,
        password: this.password
      };
      try {
        const results = await AuthService.login(obj);
        if (results.data.status == 401) {
          createToast('Usuário/Senha incorreta', {
            type: 'warning',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else {
          if (results.data.auth) {
            this.username = null;
            this.password = null;
            TokenService.saveToken(results.data.access_token);
            TokenService.saveRefreshToken(results.data.refresh_token);
            this.$router.push({ name: 'Home' });
          } else {
            createToast('Ocorreu algum problema tente novamente mais tarde', {
              type: 'warning',
              position: 'top-right',
              hideProgressBar: true,
              showIcon: true,
              transition: 'slide'
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
});
</script>

<style scoped>
.darkmode-menu {
  position: fixed;
  right: 0.5rem;
  top: 0.5rem;
}
</style>
