<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div class="text-theme-11 text-sm cursor-pointer">
            <ChevronLeftIcon /> Voltar
          </div>
          <div class="flex row align-center justify-between">
            <div class="text-lg font-medium mt-3">
              Trocar Senha da Conta
            </div>
          </div>
          <div>
            <div class="mt-5">
              <label for="old-password" class="form-label">Senha Antiga</label>
              <input
                v-model="oldPassword"
                id="old-password"
                type="password"
                class="form-control"
                name="old-password"
              />
            </div>
            <div class="mt-3">
              <label for="new-password" class="form-label">Nova Senha</label>
              <input
                v-model="newPassword"
                id="new-password"
                type="password"
                class="form-control"
                name="new-password"
              />
            </div>
            <div class="mt-3">
              <label for="new-password-confirmation" class="form-label"
                >Confirmação da Nova Senha</label
              >
              <input
                v-model="newPasswordConfirmation"
                id="new-password-confirmation"
                type="password"
                class="form-control"
                name="new-password-confirmation"
              />
            </div>
            <div class="mt-5 flex justify-between">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="cancel()"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="save()"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';

export default {
  name: 'ChangePassword',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    };
  },
  methods: {
    ...mapActions('users', {
      updatePassword: 'updatePassword'
    }),
    async save() {
      const checkPassword = this.newPassword === this.newPasswordConfirmation;
      if (!checkPassword) {
        createToast('As senhas não conferem.', {
          type: 'warning',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        return;
      }
      const test = await this.updatePassword({
        old_password: this.oldPassword,
        password: this.newPassword
      });
      console.log(test);
    }
  }
};
</script>
