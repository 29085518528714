<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <div class="col-span-12 mt-6">
          <div v-if="retrieveReportRunning || getMeRunning" class="w-8 h-8">
            <LoadingIcon icon="puff" class="w-8 h-8" />
          </div>
          <div v-else class="intro-y flex flex-col gap-6">
            <div class="w-100 flex flex-row justify-evenly mb-2">
              <div>
                <img
                  class="company-logo"
                  :src="report.company.logo"
                  alt="Logo Empresa"
                />
              </div>
              <div class="text-center">
                <h1 class="text-6xl font-light mr-auto capitalize"></h1>
                <p>{{ report.company.name }}</p>
                <p>
                  {{ convertDataTime(report.createdAt) }} por
                  {{ report.reporter.name }}
                </p>
                <p class="mt-2">
                  <span class="text-white bg-theme-10 py-1 px-2 badge-number"
                    >RELATÓRIO Nº {{ report.id
                    }}<span v-if="report.appends.length > 0"
                      >[<span
                        v-for="(append, indexAppend) in report.appends"
                        :key="append.id"
                        ><span v-if="indexAppend != 0">.</span
                        >{{ append.id }}</span
                      >]</span
                    ></span
                  >
                </p>
              </div>
              <div>
                <button
                  v-if="me.superuser"
                  class="btn text-white dark:text-gray-300 bg-theme-16 mr-1"
                  @click="reprintReportHandle()"
                >
                  <PrinterIcon class="block mx-auto" />
                </button>
                <button
                  class="btn text-white dark:text-gray-300 bg-theme-10 mx-1"
                  @click="printReportHandle()"
                >
                  <PrinterIcon class="block mx-auto" />
                </button>
                <button
                  class="btn text-white dark:text-gray-300 bg-theme-10 ml-1"
                >
                  <MailIcon class="block mx-auto" @click="sendMail()" />
                </button>
              </div>
            </div>
            <div
              v-for="(s, indexSection) in report.sections"
              :key="indexSection"
              class="mt-4"
            >
              <div v-if="s.type == 'section'">
                <h3 class="text-xl font-light">{{ s.name }}</h3>
                <hr class="mb-3" />
                <div
                  class="grid justify-center"
                  :class="'grid-cols-' + getColGrid(report.data[indexSection])"
                  v-for="(repeat, indexRepeat) in report.data[indexSection]"
                  :key="indexRepeat"
                >
                  <div v-for="(field, indexField) in repeat" :key="indexField">
                    <div
                      v-if="
                        field.data
                          ? Array.isArray(field.data)
                            ? field.data.length
                            : true
                          : false
                      "
                      class="py-2"
                    >
                      <div
                        v-if="Array.isArray(field.data)"
                        class="whitespace-normal break-words"
                      >
                        <span
                          class="font-bold mr-1"
                          v-if="field.type != 'fixedtext'"
                          >{{ field.name }}:</span
                        >
                        <span class="font-light">{{
                          convertArrayToText(field.data)
                        }}</span>
                      </div>
                      <div
                        v-else
                        :class="{
                          'mr-auto ml-auto':
                            field.type == 'graph' ||
                            field.type == 'fixedtext' ||
                            field.type == 'image'
                        }"
                      >
                        <span class="font-bold" v-if="field.type != 'fixedtext'"
                          >{{ field.name }}:
                        </span>
                        <span class="font-light" v-if="field.type == 'graph'"
                          ><img :src="field.data"
                        /></span>
                        <span
                          class="font-light"
                          v-else-if="field.type == 'fixedtext'"
                          v-html="processHtml(field.data)"
                        ></span>
                        <span
                          v-else-if="field.type == 'image'"
                          class="grid grid-cols-2 gap-4"
                        >
                          <span
                            class="imagem_report"
                            v-for="(img, iImg) in field.data"
                            :key="iImg"
                          >
                            <img :src="img" />
                          </span>
                        </span>
                        <span
                          class="font-light"
                          v-else-if="field.type == 'datetime-local'"
                        >
                          {{ handleDatetime(field.data) }}
                        </span>
                        <span class="font-light" v-else>{{ field.data }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="s.type == 'field'">
                <hr class="mb-3" />
                <div
                  v-for="(field, indexField) in report.data[indexSection]"
                  :key="indexField"
                  class="my-2 flex flex-row justify-start gap-2 flex-wrap"
                >
                  <div
                    v-if="field.data"
                    class="py-2"
                    :class="{
                      'mr-auto ml-auto':
                        field.type == 'graph' ||
                        field.type == 'fixedtext' ||
                        field.type == 'image'
                    }"
                  >
                    <span class="font-bold" v-if="field.type != 'fixedtext'"
                      >{{ field.name }}:
                    </span>
                    <span class="font-light" v-if="field.type == 'graph'"
                      ><img :src="field.data"
                    /></span>
                    <span
                      class="font-light"
                      v-else-if="field.type == 'fixedtext'"
                      v-html="processHtml(field.data)"
                    ></span>
                    <span
                      v-else-if="field.type == 'image'"
                      class="grid grid-cols-2 gap-4"
                    >
                      <span
                        class="imagem_report"
                        v-for="(img, iImg) in field.data"
                        :key="iImg"
                      >
                        <img :src="img" />
                      </span>
                    </span>
                    <span
                      class="font-light"
                      v-else-if="field.type == 'datetime-local'"
                    >
                      {{ handleDatetime(field.data) }}
                    </span>
                    <span class="font-light" v-else>{{ field.data }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="append in report.appends" :key="append._id">
              <div class="flex flex-row justify-end">
                <button
                  @click="handleDetachReport(append._id)"
                  class="btn text-white dark:text-gray-300 bg-theme-6"
                >
                  <XIcon class="block mx-auto" />
                </button>
              </div>
              <div
                v-for="(s, indexSection) in append.sections"
                :key="indexSection"
                class="mt-4"
              >
                <div v-if="s.type == 'section'">
                  <h3 class="text-xl font-light">{{ s.name }}</h3>
                  <hr class="mb-3" />
                  <div
                    class="flex flex-row justify-evenly"
                    v-for="(repeat, indexRepeat) in append.data[indexSection]"
                    :key="indexRepeat"
                  >
                    <div
                      v-for="(field, indexField) in repeat"
                      :key="indexField"
                    >
                      <div v-if="field.data" class="py-2">
                        <div
                          v-if="Array.isArray(field.data)"
                          class="flex flex-row"
                        >
                          <span
                            class="font-bold mr-1"
                            v-if="field.type != 'fixedtext'"
                            >{{ field.name }}:</span
                          >
                          <div v-for="(e, indexE) in field.data" :key="indexE">
                            <span style="white-space: pre" class="font-light"
                              ><span v-if="indexE != 0">, </span>{{ e }}</span
                            >
                          </div>
                        </div>
                        <div
                          v-else
                          :class="{
                            'mr-auto ml-auto':
                              field.type == 'graph' ||
                              field.type == 'fixedtext' ||
                              field.type == 'image'
                          }"
                        >
                          <span
                            class="font-bold"
                            v-if="field.type != 'fixedtext'"
                            >{{ field.name }}:
                          </span>
                          <span class="font-light" v-if="field.type == 'graph'"
                            ><img :src="field.data"
                          /></span>
                          <span
                            v-else-if="field.type == 'image'"
                            class="grid grid-cols-2 gap-4"
                          >
                            <span
                              class="imagem_report"
                              v-for="(img, iImg) in field.data"
                              :key="iImg"
                            >
                              <img :src="img" />
                            </span>
                          </span>
                          <span class="font-light" v-else>{{
                            field.data
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="s.type == 'field'">
                  <hr class="mb-3" />
                  <div
                    v-for="(field, indexField) in append.data[indexSection]"
                    :key="indexField"
                    class="my-2 flex flex-row justify-start gap-2 flex-wrap"
                  >
                    <div
                      v-if="field.data"
                      class="py-2"
                      :class="{
                        'mr-auto ml-auto':
                          field.type == 'graph' ||
                          field.type == 'fixedtext' ||
                          field.type == 'image'
                      }"
                    >
                      <span class="font-bold" v-if="field.type != 'fixedtext'"
                        >{{ field.name }}:
                      </span>
                      <span class="font-light" v-if="field.type == 'graph'"
                        ><img :src="field.data" class="ml-auto"
                      /></span>
                      <span
                        class="font-light"
                        v-else-if="field.type == 'fixedtext'"
                        v-html="processHtml(field.data)"
                      ></span>
                      <span
                        v-else-if="field.type == 'image'"
                        class="grid grid-cols-2 gap-4"
                      >
                        <span
                          class="imagem_report"
                          v-for="(img, iImg) in field.data"
                          :key="iImg"
                        >
                          <img :src="img" />
                        </span>
                      </span>
                      <span
                        class="font-light"
                        v-else-if="field.type == 'datetime-local'"
                      >
                        {{ handleDatetime(field.data) }}
                      </span>
                      <span class="font-light" v-else>{{ field.data }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row justify-end">
              <a
                class="
                  btn
                  text-white
                  dark:text-gray-300
                  bg-theme-13
                  uppercase
                  font-medium
                "
                href="javascript:;"
                data-toggle="modal"
                data-target="#modal-append"
              >
                <FilePlusIcon class="block mr-2" /> Anexar Relatório
              </a>
            </div>
            <div class="flex flex-row justify-evenly">
              <div v-if="report.signature_reporter">
                <img :src="report.signature_reporter.data" />
                <p class="text-center mt-2">
                  {{ report.signature_reporter?.user?.name }}
                </p>
              </div>
              <div v-else>
                <div v-if="report.reporter._id == me._id">
                  <a
                    class="
                      btn
                      text-white
                      dark:text-gray-300
                      bg-theme-10
                      uppercase
                      font-bold
                      px-5
                      py-3
                    "
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#modal-sign"
                  >
                    Assinatura do Relator
                  </a>
                </div>
              </div>
              <div v-if="report.signature_client">
                <img :src="report.signature_client.data" />
                <p class="text-center mt-2">
                  {{ report.signature_client?.user?.name }}
                </p>
              </div>
              <div v-else>
                <a
                  class="
                    btn
                    text-white
                    dark:text-gray-300
                    bg-theme-1
                    uppercase
                    font-bold
                    px-5
                    py-3
                  "
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#modal-sign-client"
                >
                  Assinatura do Cliente
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Modal Content -->
    <div id="modal-append" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">Anexar Relatórios</h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <TomSelect
                v-if="!listReportsAppendablesRunning"
                v-model="reportsToAppend"
                :options="{
                  placeholder: 'Selecione um relatório'
                }"
              >
                <option
                  v-for="option in appendableReports"
                  :key="option._id"
                  :value="option._id"
                >
                  <span v-if="option.id == 0">{{ option.name }}</span>
                  <span v-else>Relatório Nº {{ option.id }}</span>
                </option>
              </TomSelect>
              <p class="text-center mt-2">
                Apenas relatórios não assinados podem ser anexados.
              </p>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="attachReports()"
            >
              Anexar
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <!-- BEGIN: Modal Sign Reporter Content -->
    <div id="modal-sign" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">
              Assinar Relatório Relator
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <CanvasSign
                ref="canvasSignReporter"
                background-color="#fff"
                :width="420"
              />
              <p class="text-center btn-danger mt-2 sign-warning">
                Relatórios assinados não poderam mais serem excluidos.
              </p>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-warning w-20 mr-1"
              @click="clearSign()"
            >
              Limpar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="SignReportHandle()"
            >
              Assinar
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Sign Reporter Content -->
    <!-- BEGIN: Modal Sign Client Content -->
    <div id="modal-sign-client" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">
              Assinar Relatório Cliente
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              {{ clientSelected }}
              <TomSelect
                id="select-client"
                v-model="clientSelected"
                :options="{
                  placeholder: 'Selecione um Cliente',
                  allowEmptyOption: true,
                  load: loadClients,
                  shouldLoad: query => query.length > 2
                }"
              >
                <option
                  v-for="option in clientsToSign"
                  :key="option._id"
                  :value="option._id"
                >
                  <span>{{ option.name }}</span>
                </option>
              </TomSelect>
              <CanvasSign
                ref="canvasSignClient"
                background-color="#fff"
                :width="420"
              />
              <p class="text-center btn-danger mt-2 sign-warning">
                Relatórios assinados não poderam mais serem excluidos.
              </p>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-warning w-20 mr-1"
              @click="clearSignClient()"
            >
              Limpar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="SignClientHandle()"
            >
              Assinar
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Sign Client Content -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import CanvasSign from 'vue-canvas-sign';
import ReportsService from '@/services/reports/reports.service';

export default {
  name: 'View-Report',
  components: {
    CanvasSign
  },
  data() {
    return {
      searchArgs: {
        page: 1,
        page_size: 10,
        name: ''
      },
      searchArgsClients: {
        page: 1,
        page_size: 10,
        name: ''
      },
      uuid: this.$route.params.id,
      reportsToAppend: '',
      signReporterImg: '',
      signClientImg: '',
      clientSelected: undefined
    };
  },
  async created() {
    if (this.uuid) this.retrieveReport(this.uuid);
    this.getMe();
    this.listReportsAppendables(this.uuid);
    this.listClients(this.searchArgsClients);
  },
  computed: {
    ...mapState({
      report: state => state.reports.report,
      reports: state => state.reports.reports,
      retrieveReportRunning: state => state.reports.retrieveReportRunning,
      listReportsAppendablesRunning: state =>
        state.reports.listReportsAppendablesRunning,
      signReportRunning: state => state.reports.signReportRunning,
      printReportRunning: state => state.reports.printReportRunning,
      pdf: state => state.reports.pdf,
      me: state => state.users.me,
      getMeRunning: state => state.users.getMeRunning,
      clients: state => state.users.clients,
      listClientsRunning: state => state.users.listClientsRunning
    }),
    appendableReports() {
      if (
        this.reports &&
        this.reports.results &&
        this.reports.results.length > 0
      ) {
        const array = this.reports.results;
        array.unshift({ _id: 0, id: 0, name: 'Nenhum' });
        return array.filter(e => e._id != this.uuid);
      } else return [];
    },
    clientsToSign() {
      if (
        this.clients &&
        this.clients.results &&
        this.clients.results.length > 0
      ) {
        return this.clients.results;
      } else return [];
    }
  },
  methods: {
    ...mapActions('reports', {
      retrieveReport: 'retrieveReport',
      listReportsAppendables: 'listReportsAppendables',
      attachReport: 'attachReport',
      detachReport: 'detachReport',
      signReport: 'signReport',
      printReport: 'printReport'
    }),
    ...mapActions('users', {
      getMe: 'getMe',
      listClients: 'listClients'
    }),
    convertDataTime(value) {
      const date = new Date(value);
      return (
        date.toLocaleDateString('pt-BR') +
        ' às ' +
        date.toLocaleTimeString('pt-BR')
      );
    },
    async attachReports() {
      if (this.reportsToAppend) {
        const result = await this.attachReport({
          uuid: this.report._id,
          appends: [this.reportsToAppend]
        });
        if (result) {
          createToast('Ocorreu algum erro a anexar o relatório', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else {
          createToast('Relatório anexado com sucesso', {
            type: 'success',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
          this.retrieveReport(this.uuid);
        }
      } else {
        createToast('Selecione um relatório para anexar', {
          type: 'warning',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      }
    },
    async handleDetachReport(append) {
      const result = await this.detachReport({
        uuid: this.report._id,
        appends: [append]
      });
      if (result) {
        createToast('Ocorreu algum erro a desanexar o relatório', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Relatório desanexado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.retrieveReport(this.uuid);
      }
    },
    clearSign() {
      this.$refs.canvasSignReporter.clear();
    },
    clearSignClient() {
      this.$refs.canvasSignClient.clear();
    },
    async SignReportHandle() {
      this.$refs.canvasSignReporter.save(img => {
        this.signReporterImg = img;
      });
      if (this.signReporterImg) {
        const result = await this.signReport({
          uuid: this.report._id,
          sign: this.signReporterImg
        });
        if (result) {
          console.log(result);
        } else {
          console.log('Assinou');
        }
      }
      this.retrieveReport(this.uuid);
    },
    async SignClientHandle() {
      this.$refs.canvasSignClient.save(img => {
        this.signClientImg = img;
      });
      if (this.signClientImg) {
        if (this.clientSelected != 0) {
          const result = await this.signReport({
            uuid: this.report._id,
            sign: this.signClientImg,
            client: this.clientSelected
          });
          if (result) {
            console.log(result);
          } else {
            console.log('Client Assinou');
          }
        }
      }
      this.retrieveReport(this.uuid);
    },
    async printReportHandle() {
      ReportsService.print(this.report._id)
        .then(response => {
          this.forceFileDownload(response, `report-${this.uuid}.pdf`);
        })
        .catch(e => {
          createToast(
            'Ocorreu algum erro ao gerar o PDF, tente novamente mais tarde',
            {
              type: 'danger',
              position: 'top-right',
              hideProgressBar: true,
              showIcon: true,
              transition: 'slide'
            }
          );
        });
    },
    async reprintReportHandle() {
      ReportsService.reprint(this.report._id)
        .then(response => {
          this.forceFileDownload(response, `report-${this.uuid}.pdf`);
        })
        .catch(e => {
          createToast(
            'Ocorreu algum erro ao gerar o PDF, tente novamente mais tarde',
            {
              type: 'danger',
              position: 'top-right',
              hideProgressBar: true,
              showIcon: true,
              transition: 'slide'
            }
          );
        });
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    processHtml(html) {
      html = html.replaceAll('&lt;', '<');
      html = html.replaceAll('&gt;', '>');
      return html;
    },
    getColGrid(section) {
      const length = section[0].length;
      if (length <= 3) return length;
      return 3;
    },
    convertArrayToText(array) {
      if (!Array.isArray(array)) return array;
      let fullText = '';
      array.forEach(text => {
        fullText += text + ', ';
      });
      return fullText.slice(0, -2);
    },
    async sendMail() {
      const result = await ReportsService.sendMail(this.report._id);
      if (result && result.status && result.status == 200) {
        createToast('Email enviado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Ocorreu algum erro ao enviar o email', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      }
    },
    handleDatetime(date) {
      /* eslint-disable */
      const s = date;
      if (s.length == 12) {
        return (
          Array(s.slice(0, 2), s.slice(2, 4), s.slice(4, 8)).join('/') +
          ' ' +
          Array(s.slice(8, 10), s.slice(10, 12)).join(':')
        );
      } else if (s.length == 8) {
        return Array(s.slice(0, 2), s.slice(2, 4), s.slice(4, 8)).join('/');
      } else return s;
    },
    async loadClients(query, callback) {
      this.searchArgsClients.name = query;
      console.log(this.searchArgsClients);
      await this.listClients(this.searchArgsClients);
      console.log(this.clientsToSign);
      callback(this.clientsToSign);
    }
  }
};
</script>

<style>
.company-logo {
  height: 80px;
}
.badge-number {
  border-radius: 0.5em;
}
.sign-warning {
  border-radius: 0.5em;
}

.imagem_report img {
  height: 400px;
  width: 400px;
}
</style>
