const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'Home',
        title: 'Dashboard'
      },
      {
        icon: 'ClipboardIcon',
        pageName: 'reports',
        title: 'Relatórios'
      },
      {
        icon: 'ClipboardIcon',
        pageName: 'unsigns-reports',
        title: 'Relatórios Não Assinados'
      },
      {
        icon: 'FileTextIcon',
        pageName: 'forms',
        title: 'Formulários'
      },
      'devider',
      {
        fa_icon: 'far fa-building',
        pageName: 'companies',
        title: 'Empresas',
        superuser: true
      },
      {
        icon: 'UsersIcon',
        pageName: 'users',
        title: 'Usuários e Clientes'
      },
      {
        fa_icon: 'fas fa-users',
        pageName: 'groups',
        title: 'Grupos',
        superuser: true
      },
      {
        fa_icon: 'fas fa-university',
        pageName: 'policies',
        title: 'Políticas',
        superuser: true
      } /*
      {
        icon: 'BoxIcon',
        pageName: 'side-menu-menu-layout',
        title: 'Menu Layout',
        subMenu: [
          {
            icon: '',
            pageName: 'side-menu-dashboard-overview-1',
            title: 'Side Menu',
            ignore: true
          },
          {
            icon: '',
            pageName: 'simple-menu-dashboard-overview-1',
            title: 'Simple Menu',
            ignore: true
          },
          {
            icon: '',
            pageName: 'top-menu-dashboard-overview-1',
            title: 'Top Menu',
            ignore: true
          }
        ]
      }, */
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
