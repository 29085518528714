<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div
    class="dark-mode-switcher cursor-pointer box dark:bg-transparent rounded-full flex items-center justify-between z-50 dark-mode-box"
    @click="switchMode"
  >
    <div class="flex items-center justify-start">
      <MoonIcon class="w-4 h-4 mr-2" />
      <div class="mr-4">Dark Mode</div>
      </div>
    <input type="checkbox" class="form-check-switch" v-model="inital">
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>
import TokenService from '@/services/storage.service'
import { defineComponent, onMounted } from 'vue'
// import { useStore } from '@/store'

export default defineComponent({
  setup() {
    /* const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode) */
    // const darkMode = TokenService.getDarkMode() ? ((TokenService.getDarkMode() == 'true')) : false
    const inital = TokenService.getDarkMode() ? ((TokenService.getDarkMode() == 'true')) : false

    const setDarkModeClass = () => {
      const darkMode = TokenService.getDarkMode() ? ((TokenService.getDarkMode() == 'true')) : false
      darkMode
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    }

    const switchMode = () => {
      // store.dispatch('main/setDarkMode', !darkMode.value)
      const darkMode = TokenService.getDarkMode() ? ((TokenService.getDarkMode() == 'true')) : false
      TokenService.saveDarkMode(!darkMode)
      setDarkModeClass()
    }

    onMounted(() => {
      setDarkModeClass()
    })

    return {
      switchMode,
      inital
    }
  }
})
</script>

<style scoped>
 .dark-mode-switcher {
   background: transparent;
   width: 100%;
 }
 .dark-mode-bg {
   background: white;
 }
</style>
