import companyService from '@/services/iam/company.service';

const state = {
  company: null,
  companies: [],

  createCompanyRunning: false,
  retrieveCompanyRunning: false,
  listCompaniesRunning: false,
  deleteCompanyRunning: false,
  updateCompanyRunning: false
};

const getters = {};

const actions = {
  listCompanies: async ({ commit }, args) => {
    try {
      let results = companyService.list(args);
      commit('listCompaniesRunning', true);
      results = await results;
      commit('listCompaniesSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  retrieveCompany: async ({ commit }, args) => {
    try {
      let result = companyService.retrieve(args);
      commit('retrieveCompanyRunning', true);
      result = await result;
      commit('retrieveCompanySuccess', result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  createCompany: async ({ commit }, args) => {
    try {
      commit('createCompanyRunning', true);
      await companyService.create(args);
      commit('createCompanyRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  updateCompany: async ({ commit }, args) => {
    try {
      commit('updateCompanyRunning', true);
      await companyService.update(args.uuid, args);
      commit('updateCompanyRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  deleteCompany: async ({ commit }, args) => {
    try {
      commit('deleteCompanyRunning', true);
      await companyService.delete(args);
      commit('deleteCompanyRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  }
};

const mutations = {
  listCompaniesRunning(state, bool = false) {
    state.listCompaniesRunning = bool;
  },

  listCompaniesSuccess(state, result) {
    state.companies = result.data;
    state.listCompaniesRunning = false;
  },

  retrieveCompanyRunning(state, bool = false) {
    state.retrieveCompanyRunning = bool;
  },

  retrieveCompanySuccess(state, result) {
    state.company = result.data;
    state.retrieveCompanyRunning = false;
  },

  createCompanyRunning(state, bool = false) {
    state.createCompanyRunning = bool;
  },

  updateCompanyRunning(state, bool = false) {
    state.updateCompanyRunning = bool;
  },

  deleteCompanyRunning(state, bool = false) {
    state.deleteCompanyRunning = bool;
  }
};

const companies = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default companies;
export { companies };
