import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import globalComponents from './global-components';
import utils from './utils';
import './libs';
import TokenService from './services/storage.service';
import ApiService from './services/_api.service';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// SASS Theme
import './assets/sass/app.scss';

const getApiConfig = async () => {
  const apiConfig = await fetch('/data/api-config.json');
  return await apiConfig.json();
};

getApiConfig().then(function(json) {
  const myMixin = {
    data() {
      return {
        // Distribute runtime configs into every Vue component
        API_ENDPOINT: json.API,
        LOGIN_PAGE_BASE: json.LOGIN_PAGE_BASE
      };
    }
  };
  // If token exists set header
  if (TokenService.getToken()) {
    ApiService.setHeader();
  }
  // eslint-disable-next-line camelcase
  const redirect_url_full = `${json.LOGIN_PAGE_BASE}`;
  ApiService.mount401Interceptor('/login');
  ApiService.init(json.API, redirect_url_full);

  /* eslint-disable no-new */
  const app = createApp(App)
    .use(store)
    .use(router)
    .use(myMixin)
    .use(VueSweetalert2);

  globalComponents(app);
  utils(app);

  app.component('DatePicker', VueDatePicker);

  app.mount('#app');
});
