import { createRouter, createWebHistory } from 'vue-router';
import SideMenu from '../layouts/side-menu/Main.vue';
// import SimpleMenu from '../layouts/simple-menu/Main.vue'
// import TopMenu from '../layouts/top-menu/Main.vue'
import DashboardOverview from '../pages/dashboard-overview/Main.vue';

import Login from '../pages/login/Main.vue';
import Register from '../pages/register/Main.vue';
import ErrorPage from '../pages/error-page/Main.vue';

// IAM

// COMPANIES
import CompaniesLayout from '../pages/companies/Layout.vue';
import Companies from '../pages/companies/Main.vue';
import CompaniesCreate from '../pages/companies/Company.vue';

// USERS
import UsersLayout from '../pages/users/Layout.vue';
import Users from '../pages/users/Main.vue';
import User from '../pages/users/User.vue';
import ChangePassword from '../pages/users/ChangePassword.vue';

// GROUPS
import GroupsLayout from '../pages/groups/Layout.vue';
import Groups from '../pages/groups/Main.vue';
import Group from '../pages/groups/Group.vue';

// POLICIES
import PoliciesLayout from '../pages/policies/Layout.vue';
import Policies from '../pages/policies/Main.vue';
import Policy from '../pages/policies/Policy.vue';

// FORMS
import FormsLayout from '../pages/forms/Layout.vue';
import Forms from '../pages/forms/Main.vue';
import Form from '../pages/forms/Form.vue';

// REPORTS
import ReportLayout from '../pages/reports/Layout.vue';
import Report from '../pages/reports/Report.vue';
import Reports from '../pages/reports/Main.vue';
import ViewReport from '../pages/reports/ViewReport';
import ReportPrint from '../pages/reports/ReportPrint';
import UnsignsReports from '../pages/reports/UnsignsReports.vue';

const routes = [
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: '/',
        name: 'Home',
        component: DashboardOverview
      },
      {
        path: '/reports',
        name: 'reports-layout',
        component: ReportLayout,
        children: [
          {
            path: '/reports',
            name: 'reports',
            component: Reports
          },
          {
            path: '/reports/:id',
            name: 'report',
            component: Report
          },
          {
            path: '/reports/unsigns',
            name: 'unsigns-reports',
            component: UnsignsReports
          }
        ]
      },
      {
        path: '/report/:id',
        name: 'View Report',
        component: ViewReport
      },
      {
        path: '/companies',
        name: 'companies-layout',
        component: CompaniesLayout,
        children: [
          {
            path: '/companies',
            name: 'companies',
            component: Companies
          },
          {
            path: '/companies/:id',
            name: 'Company',
            component: CompaniesCreate
          }
        ]
      },
      {
        path: '/users',
        name: 'users-layout',
        component: UsersLayout,
        children: [
          {
            path: '/users',
            name: 'users',
            component: Users
          },
          {
            path: '/users/:id',
            name: 'user',
            component: User
          },
          {
            path: '/users/password',
            name: 'change-password',
            component: ChangePassword
          }
        ]
      },
      {
        path: '/groups',
        name: 'groups-layout',
        component: GroupsLayout,
        children: [
          {
            path: '/groups',
            name: 'groups',
            component: Groups
          },
          {
            path: '/groups/:id',
            name: 'group',
            component: Group
          }
        ]
      },
      {
        path: '/policies',
        name: 'policies-layout',
        component: PoliciesLayout,
        children: [
          {
            path: '/policies',
            name: 'policies',
            component: Policies
          },
          {
            path: '/policies/:id',
            name: 'policy',
            component: Policy
          }
        ]
      },
      {
        path: '/forms',
        name: 'forms-layout',
        component: FormsLayout,
        children: [
          {
            path: '/forms',
            name: 'forms',
            component: Forms
          },
          {
            path: '/forms/:id',
            name: 'form',
            component: Form
          }
        ]
      }
    ]
  },
  {
    path: '/report/print/:id',
    name: 'report-print',
    component: ReportPrint
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

export default router;
