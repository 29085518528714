import ApiService from '../_api.service';

const defaultPath = '/v1/iam/groups';

const groupsService = {
  list: function(args) {
    let queryParams = '?';
    Object.keys(args).forEach(key => {
      if (args[key]) queryParams += key + '=' + args[key] + '&';
    });
    return ApiService.get(`${defaultPath}/${queryParams}`);
  },

  retrieve: function(uuid) {
    if (!uuid) return;
    return ApiService.get(`${defaultPath}/${uuid}`);
  },

  create: function(model) {
    return ApiService.post(`${defaultPath}`, model);
  },

  update: function(uuid, model) {
    if (!uuid || !model) return;
    return ApiService.patch(`${defaultPath}/${uuid}`, model);
  },

  delete: function(uuid) {
    if (!uuid) return;
    return ApiService.delete(`${defaultPath}/${uuid}`);
  },

  attachPolicy: function(uuid, model) {
    if (!uuid || !model) return;
    return ApiService.post(`${defaultPath}/${uuid}/AttachPolicy`, model);
  },

  detachPolicy: function(uuid, model) {
    if (!uuid || !model) return;
    return ApiService.post(`${defaultPath}/${uuid}/DetachPolicy`, model);
  },

  attachUser: function(uuid, model) {
    if (!uuid || !model) return;
    return ApiService.post(`${defaultPath}/${uuid}/attachUser`, model);
  },

  detachUser: function(uuid, model) {
    if (!uuid || !model) return;
    return ApiService.post(`${defaultPath}/${uuid}/detachUser`, model);
  },

  listUsers: function(args) {
    let queryParams = '?';
    Object.keys(args).forEach(key => {
      if (args[key]) queryParams += key + '=' + args[key] + '&';
    });
    return ApiService.get(`${defaultPath}/users${queryParams}`);
  }
};

export default groupsService;
export { groupsService };
