<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Forms -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              <div class="p-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Pesquisar"
                  v-model="searchArgs.name"
                />
              </div>
            </h2>
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
              <button
                @click.prevent="handleCreate()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                  mr-2
                "
              >
                <PlusIcon class="hidden sm:block w-4 h-4 mr-2" />
                Criar Formulário
              </button>
              <button
                @click.prevent="handleUpdate()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                "
              >
                <RefreshCcwIcon class="hidden sm:block w-4 h-4 mr-2" />
                Atualizar
              </button>
            </div>
          </div>
          <div v-if="listFormsRunning" class="mt-5 w-10 h-10">
            <LoadingIcon icon="puff" class="mt-5 w-10 h-10" />
          </div>
          <div
            v-else
            class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
          >
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">NOME</th>
                  <th class="whitespace-nowrap">EMPRESA</th>
                  <th class="whitespace-nowrap">ATIVO</th>
                  <th class="whitespace-nowrap">PÚBLICO</th>
                  <th class="text-center whitespace-nowrap">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="form in queriedData" :key="form._id" class="intro-x">
                  <td>
                    <div class="font-medium whitespace-nowrap">
                      {{ form.name }}
                    </div>
                  </td>
                  <td class="w-60">
                    <div class="w-60 text-gray-600 text-xs truncate mt-0.5">
                      {{ form.company.name }}
                    </div>
                  </td>
                  <td class="w-20">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      <CheckIcon v-if="form.active" class="text-theme-10 h-4" />
                      <XIcon class="text-theme-6 h-4" v-else />
                    </div>
                  </td>
                  <td class="w-20">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      <CheckIcon v-if="form.public" class="text-theme-10 h-4" />
                      <XIcon class="text-theme-6 h-4" v-else />
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <div
                        class="
                          flex
                          items-center
                          mr-3
                          text-theme-10
                          cursor-pointer
                        "
                        @click.prevent="handleDupe(form._id)"
                      >
                        <CopyIcon class="w-5 h-5 mr-1" /> Duplicar
                      </div>
                      <div
                        class="
                          flex
                          items-center
                          mr-3
                          text-theme-11
                          cursor-pointer
                        "
                        @click.prevent="handleEdit(form._id)"
                      >
                        <EditIcon class="w-5 h-5 mr-1" /> Editar
                      </div>
                      <div
                        class="flex items-center text-theme-6 cursor-pointer"
                        @click.prevent="handleDelete(form._id)"
                      >
                        <Trash2Icon class="w-5 h-5 mr-1" /> Excluir
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center text-lg p-5" v-if="queriedData.length == 0">
              No Data
            </div>
            <div
              class="
                intro-y
                flex flex-wrap
                sm:flex-row sm:flex-nowrap
                items-center
                mt-3
              "
            >
              <ul class="pagination">
                <li v-if="searchArgs.page > 2">
                  <span class="pagination__link" @click="gotoPage('first')">
                    <ChevronsLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="searchArgs.page > 1">
                  <span class="pagination__link" @click="gotoPage('back')">
                    <ChevronLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <div v-for="(page, index) in pagesArray" :key="index">
                  <li>
                    <span
                      @click="gotoPage(page)"
                      class="pagination__link"
                      :class="{
                        'pagination__link--active': page == searchArgs.page
                      }"
                      >{{ page }}</span
                    >
                  </li>
                </div>
                <li v-if="qntdPage > searchArgs.page">
                  <span class="pagination__link" @click="gotoPage('next')">
                    <ChevronRightIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="qntdPage > searchArgs.page + 1">
                  <span class="pagination__link" @click="gotoPage('last')">
                    <ChevronsRightIcon class="w-4 h-4" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- END: Forms -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import _ from 'lodash';

export default defineComponent({
  setup() {},
  data() {
    return {
      searchArgs: {
        page: 1,
        page_size: 10,
        name: ''
      },
      oldSearchArgs: {
        page: 1,
        page_size: 10,
        name: ''
      }
    };
  },
  async created() {
    await this.listForms(this.searchArgs);
  },
  computed: {
    ...mapState({
      forms: state => state.forms.forms,
      form: state => state.forms.form,
      listFormsRunning: state => state.forms.listFormsRunning
    }),
    queriedData() {
      if (this.forms && this.forms.results && this.forms.results.length > 0) {
        const results = this.forms.results;
        results.forEach(r => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    qntdPage() {
      if (this.forms && this.forms.length) {
        const length = this.forms.length;
        return Math.ceil(length / this.searchArgs.page_size);
      } else return 0;
    },
    pagesArray() {
      if (this.forms && this.forms.length) {
        const array = [];
        for (
          let i = this.searchArgs.page - 1;
          i <=
          (this.qntdPage > 3
            ? this.searchArgs.page + 1 > this.qntdPage
              ? this.qntdPage
              : this.searchArgs.page == 1 && this.qntdPage > 3
              ? 3
              : this.searchArgs.page + 1
            : this.qntdPage);
          i++
        ) {
          if (i > 0) array.push(i);
        }
        return array;
      } else return [];
    }
  },
  methods: {
    ...mapActions('forms', {
      listForms: 'listForms',
      deleteForm: 'deleteForm',
      dupeForm: 'dupeForm'
    }),
    handleCreate() {
      this.$router.push({ name: 'form', params: { id: 'create' } });
    },
    convertDatetime(data) {
      data = new Date(data);
      return data.toLocaleString();
    },
    async handleUpdate() {
      await this.listForms(this.searchArgs);
    },
    handleDelete(id) {
      this.$swal
        .fire({
          title: 'Você tem certeza?',
          text: 'Essa ação não pode ser revertida',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, deletar!',
          cancelButtonText: 'Cancelar'
        })
        .then(async result => {
          if (result.isConfirmed) {
            const result = await this.deleteForm(id);
            if (result) {
              createToast('Erro ao deletar o formulário', {
                type: 'danger',
                position: 'top-right',
                hideProgressBar: true,
                showIcon: true,
                transition: 'slide'
              });
            } else {
              createToast('Formulário Deletado com Sucesso', {
                type: 'success',
                position: 'top-right',
                hideProgressBar: true,
                showIcon: true,
                transition: 'slide'
              });
              this.listForms(this.searchArgs);
            }
          }
        });
    },
    async handleDupe(id) {
      const result = await this.dupeForm(id);
      if (result) {
        createToast('Erro ao duplicar o formulário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Formulário duplicado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.$router.push({ name: 'form', params: { id: this.form._id } });
      }
    },
    handleEdit(id) {
      this.$router.push({ name: 'form', params: { id: id } });
    },
    gotoPage(page) {
      if (typeof page == 'number') {
        if (this.searchArgs.page != page) {
          this.searchArgs.page = page;
          this.listForms(this.searchArgs);
        }
      } else {
        switch (page) {
          case 'first':
            this.searchArgs.page = 1;
            break;
          case 'back':
            this.searchArgs.page = this.searchArgs.page - 1;
            break;
          case 'next':
            this.searchArgs.page = this.searchArgs.page + 1;
            break;
          case 'last':
            this.searchArgs.page = this.qntdPage;
            break;
        }
      }
    }
  },
  watch: {
    searchArgs: {
      handler: _.debounce(function(val) {
        if (this.oldSearchArgs.name != val.name) {
          val.page = 1;
        }
        this.oldSearchArgs = _.cloneDeep(val);
        this.listForms(val);
      }, 300),
      deep: true
    }
  }
});
</script>
