<template>
  <div class="printable px-4">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12">
        <div class="col-span-12 mt-6">
          <div v-if="!retrieveReportRunning" class="flex flex-col gap-6">
            <div class="w-100 flex flex-row justify-evenly mb-2">
              <div>
                <img
                  class="company-logo"
                  :src="report.company.logo"
                  alt="Logo Empresa"
                />
              </div>
              <div class="text-center">
                <h1 class="text-2xl font-light mr-auto capitalize">
                  {{ report.form ? report.form.name : null }}
                </h1>
                <p>{{ report.company.name }}</p>
                <p>
                  {{ convertDataTime(report.createdAt) }} por
                  {{ report.reporter.name }}
                </p>
                <p class="mt-2">
                  <span
                    class="font-bold text-black text-xl py-1 px-2 badge-number"
                    >RELATÓRIO Nº {{ report.id
                    }}<span v-if="report.appends.length > 0"
                      >[<span
                        v-for="(append, indexAppend) in report.appends"
                        :key="append.id"
                        ><span v-if="indexAppend != 0">.</span
                        >{{ append.id }}</span
                      >]</span
                    ></span
                  >
                </p>
              </div>
              <div>
                <button
                  class="no-print btn text-white dark:text-gray-300 bg-theme-10 mx-1"
                  @click="printReportHandle()"
                >
                  <PrinterIcon class="block mx-auto" />
                </button>
              </div>
            </div>
            <div
              v-for="(s, indexSection) in report.sections"
              :key="indexSection"
              class="mt-4"
            >
              <div v-if="s.type == 'section'">
                <h3 class="text-xl font-light">{{ s.name }}</h3>
                <hr class="mb-3" />
                <div
                  class="grid justify-center"
                  :class="'grid-cols-' + getColGrid(report.data[indexSection])"
                  v-for="(repeat, indexRepeat) in report.data[indexSection]"
                  :key="indexRepeat"
                >
                  <div v-for="(field, indexField) in repeat" :key="indexField">
                    <div
                      v-if="
                        field.data
                          ? Array.isArray(field.data)
                            ? field.data.length
                            : true
                          : false
                      "
                      class="py-2"
                    >
                      <div
                        v-if="Array.isArray(field.data)"
                        class="whitespace-normal break-words"
                      >
                        <span
                          class="font-bold mr-1"
                          v-if="field.type != 'fixedtext'"
                          >{{ field.name }}:</span
                        >
                        <span
                          class="font-light"
                          v-else-if="field.type == 'datetime-local'"
                        >
                          {{ handleDatetime(field.data) }}
                        </span>
                        <span class="font-light">{{
                          convertArrayToText(field.data)
                        }}</span>
                      </div>
                      <div
                        v-else
                        :class="{
                          'mr-auto ml-auto':
                            field.type == 'graph' || field.type == 'fixedtext'
                        }"
                      >
                        <span class="font-bold" v-if="field.type != 'fixedtext'"
                          >{{ field.name }}:
                        </span>
                        <span class="font-light" v-if="field.type == 'graph'"
                          ><img :src="field.data"
                        /></span>
                        <span
                          class="font-light"
                          v-else-if="field.type == 'fixedtext'"
                          v-html="processHtml(field.data)"
                        ></span>
                        <span
                          v-else-if="field.type == 'image'"
                          class="grid grid-cols-2 gap-4"
                        >
                          <span
                            class="imagem_report"
                            v-for="(img, iImg) in field.data"
                            :key="iImg"
                          >
                            <img :src="img" />
                          </span>
                        </span>
                        <span
                          class="font-light"
                          v-else-if="field.type == 'datetime-local'"
                        >
                          {{ handleDatetime(field.data) }}
                        </span>
                        <span class="font-light" v-else>{{ field.data }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="s.type == 'field'">
                <hr class="mb-3" />
                <div
                  v-for="(field, indexField) in report.data[indexSection]"
                  :key="indexField"
                  class="my-2 flex flex-row justify-start gap-2 flex-wrap"
                >
                  <div v-if="field.data" class="py-2">
                    <span class="font-bold">{{ field.name }}: </span>
                    <span v-if="field.type == 'graph'">
                      <img :src="field.data" />
                    </span>
                    <span v-else-if="field.type == 'fixedtext'">
                      <span v-html="processHtml(field.data)"></span>
                    </span>
                    <span
                      v-else-if="field.type == 'image'"
                      class="grid grid-cols-2 gap-4"
                    >
                      <span
                        class="imagem_report"
                        v-for="(img, iImg) in field.data"
                        :key="iImg"
                      >
                        <img :src="img" />
                      </span>
                    </span>
                    <span
                      class="font-light"
                      v-else-if="field.type == 'datetime-local'"
                    >
                      {{ handleDatetime(field.data) }}
                    </span>
                    <span class="font-light" v-else>{{ field.data }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="append in report.appends" :key="append._id">
              <div
                v-for="(s, indexSection) in append.sections"
                :key="indexSection"
                class="mt-4"
              >
                <div v-if="s.type == 'section'">
                  <h3 class="text-xl font-light">{{ s.name }}</h3>
                  <hr class="mb-3" />
                  <div
                    class="flex flex-row justify-evenly"
                    v-for="(repeat, indexRepeat) in append.data[indexSection]"
                    :key="indexRepeat"
                  >
                    <div
                      v-for="(field, indexField) in repeat"
                      :key="indexField"
                    >
                      <div v-if="field.data" class="py-2">
                        <div
                          v-if="Array.isArray(field.data)"
                          class="flex flex-row"
                        >
                          <span class="font-bold mr-1">{{ field.name }}:</span>
                          <div v-for="(e, indexE) in field.data" :key="indexE">
                            <span style="white-space: pre" class="font-light"
                              ><span v-if="indexE != 0">, </span>{{ e }}</span
                            >
                          </div>
                        </div>
                        <div v-else>
                          <span class="font-bold">{{ field.name }}: </span>
                          <span v-if="field.type == 'graph'">
                            <img :src="field.data" />
                          </span>
                          <span v-else-if="field.type == 'fixedtext'">
                            <span v-html="processHtml(field.data)"></span>
                          </span>
                          <span
                            v-else-if="field.type == 'image'"
                            class="grid grid-cols-2 gap-4"
                          >
                            <span
                              class="imagem_report"
                              v-for="(img, iImg) in field.data"
                              :key="iImg"
                            >
                              <img :src="img" />
                            </span>
                          </span>
                          <span
                            class="font-light"
                            v-else-if="field.type == 'datetime-local'"
                          >
                            {{ handleDatetime(field.data) }}
                          </span>
                          <span class="font-light" v-else>{{
                            field.data
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="s.type == 'field'">
                  <hr class="mb-3" />
                  <div
                    v-for="(field, indexField) in append.data[indexSection]"
                    :key="indexField"
                    class="my-2 flex flex-row justify-start gap-2 flex-wrap"
                  >
                    <div v-if="field.data" class="py-2">
                      <span class="font-bold">{{ field.name }}: </span>
                      <span v-if="field.type == 'graph'">
                        <img :src="field.data" />
                      </span>
                      <span v-else-if="field.type == 'fixedtext'">
                        <span v-html="processHtml(field.data)"></span>
                      </span>
                      <span
                        v-else-if="field.type == 'image'"
                        class="grid grid-cols-2 gap-4"
                      >
                        <span
                          class="imagem_report"
                          v-for="(img, iImg) in field.data"
                          :key="iImg"
                        >
                          <img :src="img" />
                        </span>
                      </span>
                      <span
                        class="font-light"
                        v-else-if="field.type == 'datetime-local'"
                      >
                        {{ handleDatetime(field.data) }}
                      </span>
                      <span class="font-light" v-else>{{ field.data }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row justify-evenly">
              <div v-if="report.signature_reporter">
                <img :src="report.signature_reporter.data" />
                <p class="text-center mt-2">
                  {{ report.signature_reporter.user.name }}
                </p>
              </div>
              <div v-if="report.signature_client">
                <img :src="report.signature_client.data" />
                <p class="text-center mt-2">
                  {{ report.signature_client.user.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ApiService from '../../services/_api.service';
import ReportsService from '@/services/reports/reports.service';
import { createToast } from 'mosha-vue-toastify';

export default {
  data() {
    return {
      uuid: this.$route.params.id
    };
  },
  created() {
    if (this.$route.query.token) {
      ApiService.setHeaderBasic(this.$route.query.token);
    }
    if (this.uuid) this.retrieveReport(this.uuid);
  },
  computed: {
    ...mapState({
      report: state => state.reports.report,
      retrieveReportRunning: state => state.reports.retrieveReportRunning
    })
  },
  methods: {
    ...mapActions('reports', {
      retrieveReport: 'retrieveReport'
    }),
    convertDataTime(value) {
      const date = new Date(value);
      return (
        date.toLocaleDateString('pt-BR') +
        ' às ' +
        date.toLocaleTimeString('pt-BR')
      );
    },
    getColGrid(section) {
      const length = section[0].length;
      if (length <= 3) return length;
      return 3;
    },
    convertArrayToText(array) {
      if (!Array.isArray(array)) return array;
      let fullText = '';
      array.forEach(text => {
        fullText += text + ', ';
      });
      return fullText.slice(0, -2);
    },
    processHtml(data) {
      return data;
    },
    handleDatetime(date) {
      /* eslint-disable */
      const s = date;
      console.log(s);
      if (s.length == 12) {
        return (
          Array(s.slice(0, 2), s.slice(2, 4), s.slice(4, 8)).join('/') +
          ' ' +
          Array(s.slice(8, 10), s.slice(10, 12)).join(':')
        );
      } else if (s.length == 8) {
        return Array(s.slice(0, 2), s.slice(2, 4), s.slice(4, 8)).join('/');
      } else return s;
    },
    async printReportHandle() {
      ReportsService.print(this.report._id)
        .then(response => {
          this.forceFileDownload(response, `report-${this.uuid}.pdf`);
        })
        .catch(e => {
          createToast(
            'Ocorreu algum erro ao gerar o PDF, tente novamente mais tarde',
            {
              type: 'danger',
              position: 'top-right',
              hideProgressBar: true,
              showIcon: true,
              transition: 'slide'
            }
          );
        });
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style lang="scss" scoped>
@media print {
  .no-print {
    display: none;
  }
}
.printable {
  background: white;
  width: 100%;
  position: absolute;
  margin: 0 0;
  top: 0;
  left: 0;
}
.company-logo {
  height: 80px;
}
.badge-number {
  border-radius: 0.5em;
}
.sign-warning {
  border-radius: 0.5em;
}
.imagem_report img {
  height: 400px;
  width: 400px;
}
</style>
