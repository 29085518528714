const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const LAST_PAGE = 'last_page_redirect'
const DARK_MODE = 'dark_mode'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },

  saveLastPage(lastPage) {
    localStorage.setItem(LAST_PAGE, lastPage)
  },

  getLastPage() {
    return localStorage.getItem(LAST_PAGE)
  },

  saveDarkMode(mode) {
    localStorage.setItem(DARK_MODE, mode)
  },

  getDarkMode() {
    return localStorage.getItem(DARK_MODE)
  }

}
export default TokenService
export { TokenService }
