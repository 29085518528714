<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <div v-if="loading" class="w-8 h-8">
          <LoadingIcon icon="puff" class="w-8 h-8" />
        </div>
        <!-- BEGIN: Wizard Layout -->
        <div class="intro-y box py-10 sm:py-20 mt-5" v-else>
          <div
            class="
              wizard
              flex flex-col
              lg:flex-row
              justify-center
              px-5
              sm:px-20
            "
          >
            <div
              class="
                intro-x
                lg:text-center
                flex
                items-center
                lg:block
                flex-1
                z-10
              "
            >
              <button
                @click.prevent="goToPage(0)"
                class="w-10 h-10 rounded-full btn"
                :class="{
                  'btn-primary': wizard[0],
                  'bg-gray-200 dark:bg-dark-1': !wizard[0]
                }"
              >
                1
              </button>
              <div
                class="lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto"
              >
                Informações Básicas
              </div>
            </div>
            <div
              class="
                intro-x
                lg:text-center
                flex
                items-center
                mt-5
                lg:mt-0 lg:block
                flex-1
                z-10
              "
            >
              <button
                @click.prevent="goToPage(1)"
                class="w-10 h-10 rounded-full btn"
                :class="{
                  'btn-primary': wizard[1],
                  'text-gray-600 bg-gray-200 dark:bg-dark-1': !wizard[1]
                }"
              >
                2
              </button>
              <div
                class="
                  lg:w-32
                  text-base
                  lg:mt-3
                  ml-3
                  lg:mx-auto
                  text-gray-700
                  dark:text-gray-600
                "
              >
                Construção do Modelo
              </div>
            </div>
            <div
              class="
                intro-x
                lg:text-center
                flex
                items-center
                mt-5
                lg:mt-0 lg:block
                flex-1
                z-10
              "
            >
              <button
                @click.prevent="goToPage(2)"
                class="w-10 h-10 rounded-full btn"
                :class="{
                  'btn-primary': wizard[2],
                  'text-gray-600 bg-gray-200 dark:bg-dark-1': !wizard[2]
                }"
              >
                3
              </button>
              <div
                class="
                  lg:w-32
                  text-base
                  lg:mt-3
                  ml-3
                  lg:mx-auto
                  text-gray-700
                  dark:text-gray-600
                "
              >
                <span v-if="creating">Confirmar Criação</span>
                <span v-else>Confirmar Edição</span>
              </div>
            </div>
            <div
              class="
                wizard__line
                hidden
                lg:block
                w-2/3
                bg-gray-200
                dark:bg-dark-1
                absolute
                mt-5
              "
            ></div>
          </div>

          <!-- WIZARD 1 -->
          <div class="intro-y box p-5" id="wizard-1" v-show="wizard[0]">
            <div class="mt-3">
              <label for="crud-form-1" class="form-label"
                >Nome do Formulário *</label
              >
              <input
                id="form-name"
                type="text"
                class="form-control"
                v-model="pageFields[0].name"
              />
            </div>
            <div class="mt-3">
              <label>Empresa *</label>
              <div class="mt-2">
                <TomSelect
                  v-model="pageFields[0].select"
                  :options="{
                    placeholder: 'Selecione uma empresa'
                  }"
                >
                  <option
                    v-for="company in companyOptions"
                    :key="company._id"
                    :value="company._id"
                  >
                    {{ company.name }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <div class="mt-3">
              <label>Aceitar novos relatórios?</label>
              <div class="mt-2">
                <input
                  type="checkbox"
                  class="form-check-switch"
                  v-model="pageFields[0].new"
                />
              </div>
            </div>
            <div class="mt-3">
              <label>Formulário Público para Outras Empresas?</label>
              <div class="mt-2">
                <input
                  type="checkbox"
                  class="form-check-switch"
                  v-model="pageFields[0].public"
                />
              </div>
            </div>
            <div class="text-right mt-5">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="cancel()"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="nextPage()"
              >
                Próxima
              </button>
            </div>
          </div>

          <!-- WIZARD 2 -->
          <div class="intro-y box p-5" id="wizard-2" v-show="wizard[1]">
            <div class="mt-3">
              <div
                v-for="(e, index) in model"
                :key="index"
                class="p-4 m-5 bg-theme-8 box dark:bg-theme-3"
              >
                <button
                  type="button"
                  class="btn btn-danger absolute right-1 top-1 p-0"
                  @click.prevent="removeSection(index)"
                >
                  <XIcon />
                </button>
                <div v-if="e.type == 'field'">
                  <label class="mt-2" :for="'field_name_' + index"
                    >Nome do campo *</label
                  >
                  <input
                    type="text"
                    :id="'field_name_' + index"
                    class="form-control"
                    v-model="e.name"
                  />
                  <label class="mt-2" :for="'field_type_' + index"
                    >Tipo do campo *
                  </label>
                  <TomSelect
                    :id="'field_type_' + index"
                    v-model="e.field_type"
                    :options="{
                      placeholder: 'Selecione um tipo de campo'
                    }"
                  >
                    <option
                      v-for="type in fieldTypes"
                      :key="type._id"
                      :value="type.type"
                    >
                      {{ type.name }}
                    </option>
                  </TomSelect>
                  <label class="mt-2">Aceita nulo?</label>
                  <div class="mt-2">
                    <input
                      type="checkbox"
                      class="form-check-switch"
                      v-model="e.null"
                    />
                  </div>
                  <div
                    v-if="
                      e.field_type == 'checkbox' ||
                        e.field_type == 'radio' ||
                        e.field_type == 'select'
                    "
                  >
                    <div
                      v-for="(o, indexOption) in e.options"
                      :key="indexOption"
                    >
                      <label class="mt-2" :for="'field_option_' + indexOption"
                        >Opção {{ indexOption + 1 }}</label
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          :id="'field_option_' + indexOption"
                          class="form-control"
                          v-model="o.name"
                        />
                        <button
                          type="button"
                          class="btn btn-danger ml-3 p-1"
                          @click.prevent="
                            removeOptionOnField(index, indexOption)
                          "
                        >
                          <XIcon />
                        </button>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary mr-2 mt-5"
                      @click.prevent="addOptionOnField(index)"
                    >
                      Adicionar opção
                    </button>
                  </div>
                  <div v-if="e.field_type == 'graph'">
                    <label
                      class="mt-2"
                      :for="'field_type_graph_section_' + index"
                      >Grupo de dados *
                    </label>
                    <TomSelect
                      :id="'field_type_graph_section_' + index"
                      v-model="e.data.section"
                      :options="{
                        placeholder: 'Selecione um grupo de dados (Seção)'
                      }"
                      @change="getListOfFieldsInSection(e.data.section)"
                    >
                      <option
                        v-for="sec in sectionList"
                        :key="sec"
                        :value="sec"
                      >
                        {{ sec }}
                      </option>
                    </TomSelect>
                    <label
                      class="mt-2"
                      :for="'field_type_graph_section_field_' + index"
                      v-if="showSectionFields"
                      >Campos no grupo *
                    </label>
                    <TomSelect
                      v-if="showSectionFields"
                      :id="'field_type_graph_section_field_' + index"
                      v-model="e.data.section_field"
                      :options="{
                        placeholder:
                          'Selecione um ou mais campos no grupo de dados'
                      }"
                      multiple
                    >
                      <option
                        v-for="fieldSec in fieldsInSection"
                        :key="fieldSec"
                        :value="fieldSec"
                      >
                        {{ fieldSec }}
                      </option>
                    </TomSelect>
                    <label class="mt-2" :for="'field_type_graph_ref_' + index"
                      >Valor de Referência *
                    </label>
                    <TomSelect
                      :id="'field_type_graph_ref' + index"
                      v-model="e.data.ref"
                      :options="{
                        placeholder: 'Selecione um campo de Referência'
                      }"
                    >
                      <option v-for="ref in fieldsList" :key="ref" :value="ref">
                        {{ ref }}
                      </option>
                    </TomSelect>
                    <label
                      class="mt-2"
                      :for="'field_type_graph_colors_' + index"
                      >Cores *
                    </label>
                    <button
                      type="button"
                      class="btn btn-primary ml-2 mt-5"
                      @click.prevent="addColorGraph(index)"
                    >
                      Adicionar cor
                    </button>
                    <div
                      class="form-help"
                      v-show="e.data.colors && e.data.colors.length > 0"
                    >
                      Para usar o valor de referência, use 'ref' (Ex: ref *
                      2.0)<br />
                      Para indicar que vai até o ultimo valor use 'final' (Ex:
                      final)
                    </div>
                    <div
                      class="grid grid-cols-12 gap-2 mt-2"
                      v-for="(c, indexColor) in e.data.colors"
                      :key="indexColor"
                    >
                      <input
                        type="text"
                        :id="'field_type_graph_color_' + indexColor"
                        class="form-control col-span-4"
                        placeholder="Digite o valor minimo"
                        v-model="c.min"
                      />
                      <input
                        type="text"
                        :id="'field_type_graph_color_' + indexColor"
                        class="form-control col-span-4"
                        placeholder="Digite o valor maximo"
                        v-model="c.max"
                      />
                      <div class="col-span-4">
                        <a
                          class="btn btn-primary"
                          :style="'background: ' + c.color"
                          @click="openColorPicker(index, indexColor)"
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#modal-changecolor"
                        >
                          <DropletIcon />
                        </a>
                        <button
                          type="button"
                          class="btn btn-danger ml-2"
                          @click.prevent="removeColorGraph(index, indexColor)"
                        >
                          <XIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="e.field_type == 'fixedtext'">
                    <ClassicEditor
                      v-model="e.data.section"
                      :config="{
                        toolbar: [
                          'bold',
                          'italic',
                          'link',
                          'undo',
                          'redo',
                          'numberedList',
                          'bulletedList'
                        ]
                      }"
                    />
                  </div>
                </div>
                <div v-if="e.type == 'section'">
                  <label class="mt-2" :for="'section_name_' + index"
                    >Nome da seção *</label
                  >
                  <input
                    type="text"
                    :id="'section_name_' + index"
                    class="form-control"
                    v-model="e.name"
                  />
                  <label class="mt-2" :for="'section_repeat_' + index"
                    >Repetir</label
                  >
                  <div class="input-group">
                    <input
                      type="number"
                      min="1"
                      :id="'section_repeat_' + index"
                      class="form-control"
                      v-model="e.repeats"
                    />
                    <div :id="'input_group_' + index" class="input-group-text">
                      vezes
                    </div>
                  </div>
                  <div
                    v-for="(f, indexFields) in e.fields"
                    :key="indexFields"
                    class="p-4 m-3 box"
                    :class="{
                      'bg-theme-5': indexFields % 2,
                      'bg-theme-2': !(indexFields % 2)
                    }"
                  >
                    <label
                      class="mt-2"
                      :for="'section_' + index + '_field_name_' + indexFields"
                      >Nome do campo *</label
                    >
                    <input
                      type="text"
                      :id="'section_' + index + '_field_name_' + indexFields"
                      class="form-control"
                      v-model="f.name"
                    />
                    <label
                      class="mt-2"
                      :for="'section_' + index + '_field_type_' + indexFields"
                      >Tipo do campo *</label
                    >
                    <TomSelect
                      :id="'section' + index + '_field_type_' + indexFields"
                      v-model="f.type"
                      :options="{
                        placeholder: 'Selecione um tipo de campo'
                      }"
                    >
                      <option
                        v-for="type in fieldTypes"
                        :key="type._id"
                        :value="type.type"
                      >
                        {{ type.name }}
                      </option>
                    </TomSelect>
                    <label class="mt-2">Aceita nulo?</label>
                    <div class="mt-2">
                      <input
                        type="checkbox"
                        class="form-check-switch"
                        v-model="f.null"
                      />
                    </div>
                    <div
                      v-if="
                        f.type == 'checkbox' ||
                          f.type == 'radio' ||
                          f.type == 'select'
                      "
                    >
                      <div
                        v-for="(o, indexOption) in f.options"
                        :key="indexOption"
                      >
                        <label
                          class="mt-2"
                          :for="
                            'section_' +
                              index +
                              '_field_' +
                              indexFields +
                              '_option_' +
                              indexOption
                          "
                          >Opção {{ index + 1 }}</label
                        >
                        <div class="input-group">
                          <input
                            type="text"
                            :id="
                              'section_' +
                                index +
                                '_field_' +
                                indexFields +
                                '_option_' +
                                indexOption
                            "
                            class="form-control"
                            v-model="o.name"
                          />
                          <button
                            type="button"
                            class="btn btn-danger ml-3 p-1"
                            @click.prevent="
                              removeOptionOnFieldSection(
                                index,
                                indexFields,
                                index
                              )
                            "
                          >
                            <XIcon />
                          </button>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary mr-2 mt-5"
                        @click.prevent="
                          addOptionOnFieldSection(index, indexFields)
                        "
                      >
                        Adicionar opção
                      </button>
                    </div>
                    <button
                      type="button"
                      class="btn btn-danger absolute right-1 top-1 p-0"
                      @click.prevent="removeFieldOnSection(index, indexFields)"
                    >
                      <XIcon />
                    </button>
                  </div>
                  <button
                    type="button"
                    class="btn btn-primary text-white mr-2 mt-5"
                    @click.prevent="addFieldOnSection(index)"
                  >
                    Adicionar campo na seção
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                @click.prevent="addField()"
              >
                Adicionar campo
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click.prevent="addSection()"
              >
                Adicionar seção
              </button>
            </div>
            <div class="text-right mt-5">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="previousPage()"
              >
                Voltar
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="nextPage()"
              >
                Próxima
              </button>
            </div>
          </div>
          <!-- WIZARD 2 -->
          <div class="intro-y box p-5" id="wizard-3" v-show="wizard[2]">
            <div class="my-5 py-5">
              <h2 class="text-lg font-medium text-center">
                <span v-if="creating"
                  >Você deseja confirmar a criação do formulário?</span
                >
                <span v-else
                  >Você deseja confirmar a edição do formulário?</span
                >
              </h2>
            </div>
            <div class="text-right mt-5">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="previousPage()"
              >
                Voltar
              </button>
              <span v-if="creating">
                <button
                  type="button"
                  class="btn btn-primary w-24"
                  @click.prevent="create()"
                >
                  Criar
                </button>
              </span>
              <span v-else>
                <button
                  type="button"
                  class="btn btn-primary w-24"
                  @click.prevent="save()"
                >
                  Salvar
                </button>
              </span>
            </div>
          </div>
        </div>
        <!-- END: Wizard Layout -->
      </div>
    </div>
    <!-- BEGIN: Modal Content -->
    <div id="modal-changecolor" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">Escolha uma cor</h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12 mx-auto">
              <Sketch v-model="colorPickerData.color" :disableFields="true" />
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="handleChangeColor()"
            >
              Escolher
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import { Sketch } from '@ckpack/vue-color';

export default defineComponent({
  setup() {
    const categories = ref([1, 3]);
    const editorConfig = {
      toolbar: {
        items: ['bold', 'italic', 'link']
      }
    };

    return {
      categories,
      editorConfig
    };
  },
  components: {
    Sketch
  },
  data() {
    return {
      param: this.$route.params.id,
      creating: false,
      pageFields: [{ name: '', select: 0, new: false, public: false }],
      model: [],
      companyOptions: [],
      wizard: [true, false, false],
      page: 0,
      modelId: 0,
      fieldsId: [],
      fieldTypes: [],
      optionsId: [[]],
      loading: true,
      colorPickerData: {
        color: '#59c7f9',
        index: -1,
        icolor: -1
      },
      showSectionFields: false,
      fieldsInSection: []
    };
  },
  async created() {
    const resultCompanies = await this.listCompanies({});
    if (resultCompanies) console.log(resultCompanies);
    // error
    else {
      this.companyOptions = this.companies.results;
      this.companyOptions.unshift({ _id: 0, name: 'Nenhuma' });
    }
    const resultFields = await this.listFields({});
    if (resultFields) console.log(resultFields);
    else {
      this.fieldTypes = this.fields.results;
    }
    if (this.param == 'create') {
      this.creating = true;
    } else {
      const resultForm = await this.retrieveForm(this.param);
      if (resultForm) console.log(resultForm);
      // error
      else {
        this.model = this.form.structure;
        this.pageFields = [
          {
            name: this.form.name,
            select: this.form.company,
            new: this.form.active,
            public: this.form.public
          }
        ];
      }
    }
    this.loading = false;
  },
  computed: {
    ...mapState({
      companies: state => state.company.companies
    }),
    ...mapState({
      fields: state => state.fields.fields
    }),
    ...mapState({
      form: state => state.forms.form,
      retrieveFormRunning: state => state.forms.retrieveFormRunning
    }),
    sectionList() {
      const list = [];
      this.model.forEach(m => {
        if (m.type == 'section' && m.name != '') list.push(m.name);
      });
      list.unshift('Nenhum');
      return list;
    },
    fieldsList() {
      const list = [];
      this.model.forEach(m => {
        if (m.type == 'field' && m.name != '' && m.field_type != 'graph') {
          list.push(m.name);
        }
      });
      list.unshift('Nenhum');
      return list;
    }
  },
  methods: {
    ...mapActions('company', {
      listCompanies: 'listCompanies'
    }),
    ...mapActions('fields', {
      listFields: 'listFields'
    }),
    ...mapActions('forms', {
      createForm: 'createForm',
      retrieveForm: 'retrieveForm',
      updateForm: 'updateForm'
    }),
    nextPage() {
      if (this.validate(this.page)) {
        this.wizard[this.page] = false;
        this.wizard[this.page + 1] = true;
        this.page++;
      } else {
        createToast('Alguns campos obrigatórios não foram preenchidos', {
          type: 'warning',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      }
    },
    previousPage() {
      this.wizard[this.page] = false;
      this.wizard[this.page - 1] = true;
      this.page--;
    },
    goToPage(wantedPage) {
      if (wantedPage > this.page) {
        if (this.validate(this.page)) {
          this.wizard[this.page] = false;
          this.wizard[wantedPage] = true;
          this.page = wantedPage;
        } else {
          createToast('Alguns campos obrigatórios não foram preenchidos', {
            type: 'warning',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        }
      } else {
        this.wizard[this.page] = false;
        this.wizard[wantedPage] = true;
        this.page = wantedPage;
      }
    },
    validate(page) {
      switch (page) {
        case 0: {
          if (this.pageFields[0].select != 0 && this.pageFields[0].name != '') {
            return true;
          } else return false;
        }
        case 1: {
          for (let ii = 0; ii < this.model.length; ii++) {
            if (this.model[ii].name == '') return false;
            if (this.model[ii].type == 'section') {
              if (this.model[ii].repeats <= 0) return false;
              for (let jj = 0; jj < this.model[ii].fields.length; jj++) {
                if (this.model[ii].fields[jj].name == '') return false;
              }
            }
            if (
              this.model[ii].type == 'field' &&
              this.model[ii].field_type == 'graph'
            ) {
              if (
                this.model[ii].data.section == '' ||
                this.model[ii].data.section == 'Nenhum' ||
                this.model[ii].data.ref == '' ||
                this.model[ii].data.ref == 'Nenhum'
              ) {
                return false;
              }
            }
          }
          return true;
        }
        case 2:
          return true;
        default:
          return false;
      }
    },
    addField() {
      this.model.push({
        type: 'field',
        field_type: 'text',
        name: '',
        null: false,
        options: [],
        data: {
          section: '',
          ref: '',
          colors: []
        }
      });
    },
    addSection() {
      this.model.push({ type: 'section', name: '', fields: [], repeats: 1 });
    },
    removeSection(index) {
      this.model.splice(index, 1);
    },
    addFieldOnSection(id) {
      this.model[id].fields.push({
        type: 'text',
        name: '',
        null: false,
        options: [],
        data: {
          section: '',
          ref: '',
          colors: []
        }
      });
    },
    removeFieldOnSection(section, index) {
      this.model[section].fields.splice(index, 1);
    },
    addOptionOnFieldSection(section, field) {
      this.model[section].fields[field].options.push({ name: '' });
    },
    removeOptionOnFieldSection(section, field, option) {
      this.model[section].fields[field].options.splice(option, 1);
    },
    addOptionOnField(section) {
      this.model[section].options.push({ name: '' });
    },
    removeOptionOnField(section, option) {
      this.model[section].options.splice(option, 1);
    },
    async create() {
      const result = await this.createForm({
        name: this.pageFields[0].name,
        company: this.pageFields[0].select,
        structure: this.model,
        active: this.pageFields[0].new,
        public: this.pageFields[0].public
      });
      if (result) {
        createToast('Ocorreu algum erro ao criar o formulário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Formulário criado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.$router.push({ name: 'forms' });
      }
    },
    async save() {
      const result = await this.updateForm({
        id: this.form._id,
        name: this.pageFields[0].name,
        company: this.pageFields[0].select,
        active: this.pageFields[0].new,
        public: this.pageFields[0].public,
        structure: this.model
      });
      if (result) {
        createToast('Ocorreu algum erro ao editar o formulário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Formulário editado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.$router.push({ name: 'forms' });
      }
    },
    cancel() {
      this.$router.push({ name: 'forms' });
    },
    addColorGraph(index) {
      if (!this.model[index].data.colors) this.model[index].data.colors = [];
      const c = {
        min: '',
        max: '',
        color: '#064e3b'
      };
      this.model[index].data.colors.push(c);
      console.log(this.model);
    },
    removeColorGraph(index, icolor) {
      this.model[index].data.colors.splice(icolor, 1);
    },
    openColorPicker(index, icolor) {
      this.colorPickerData.index = index;
      this.colorPickerData.icolor = icolor;
      this.colorPickerData.color = this.model[index].data.colors[icolor].color;
    },
    handleChangeColor() {
      if (this.colorPickerData.index != -1) {
        if (this.colorPickerData.color.hex) {
          this.model[this.colorPickerData.index].data.colors[
            this.colorPickerData.icolor
          ].color = this.colorPickerData.color.hex;
        } else {
          this.model[this.colorPickerData.index].data.colors[
            this.colorPickerData.icolor
          ].color = this.colorPickerData.color;
        }

        this.colorPickerData.index = -1;
        this.colorPickerData.icolor = -1;
      }
    },
    getListOfFieldsInSection(sectionSelected) {
      this.showSectionFields = true;
      const section = this.model.find(m => {
        return m.name == sectionSelected;
      });
      if (section) {
        section.fields.forEach(f => {
          this.fieldsInSection.push(f.name);
        });
      }
    }
  }
});
</script>

<style scoped>
.graph-color {
  display: inline-block;
  height: 42px;
  width: 42px;
  margin-top: 1px;
  border-radius: 5px;
}
.vc-sketch-field .vc-input__input {
  background: #313a55 !important;
}
.vc-editable-input span {
  color: #e3e3e3 !important;
}
.vc-sketch {
  background: #313a55 !important;
}
</style>
