import formsService from '@/services/reports/forms.service'

const state = {
  form: null,
  forms: [],
  me: null,

  createFormRunning: false,
  retrieveFormRunning: false,
  listFormsRunning: false,
  deleteFormRunning: false,
  updateFormRunning: false,
  dupeFormRunning: false
}

const getters = {}

const actions = {
  listForms: async ({ commit }, args) => {
    try {
      let results = formsService.list(args)
      commit('listFormsRunning', true)
      results = await results
      commit('listFormsSuccess', results.data)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  retrieveForm: async ({ commit }, args) => {
    try {
      let result = formsService.retrieve(args)
      commit('retrieveFormRunning', true)
      result = await result
      commit('retrieveFormSuccess', result.data)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  createForm: async ({ commit }, args) => {
    try {
      commit('createFormRunning', true)
      await formsService.create(args)
      commit('createFormRunning', false)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  updateForm: async ({ commit }, args) => {
    try {
      commit('updateFormRunning', true)
      await formsService.update(args.id, args)
      commit('updateFormRunning', false)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  deleteForm: async ({ commit }, args) => {
    try {
      commit('deleteFormRunning', true)
      await formsService.delete(args)
      commit('deleteFormRunning', false)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  },

  dupeForm: async ({ commit }, args) => {
    try {
      let result = formsService.dupe(args)
      commit('dupeFormRunning', true)
      result = await result
      commit('dupeFormSuccess', result.data)
      return null
    } catch (e) {
      if (e && e.response) return e.response.status
      else return 500
    }
  }
}

const mutations = {
  listFormsRunning(state, bool = false) {
    state.listFormsRunning = bool
  },

  listFormsSuccess(state, result) {
    state.forms = result.data
    state.listFormsRunning = false
  },

  retrieveFormRunning(state, bool = false) {
    state.retrieveFormRunning = bool
  },

  retrieveFormSuccess(state, result) {
    state.form = result.data
    state.retrieveFormRunning = false
  },

  createFormRunning(state, bool = false) {
    state.createFormRunning = bool
  },

  updateFormRunning(state, bool = false) {
    state.updateFormRunning = bool
  },

  deleteFormRunning(state, bool = false) {
    state.deleteFormRunning = bool
  },

  dupeFormRunning(state, bool = false) {
    state.dupeFormRunning = bool
  },

  dupeFormSuccess(state, result) {
    state.form = result.data
    state.dupeFormRunning = false
  }
}

const forms = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default forms
export { forms }
