import ApiService from '../_api.service'

const defaultPath = '/v1/reports/forms'

const formsService = {
  list: function(args) {
    let queryParams = '?'
    Object.keys(args).forEach(key => {
      if (args[key]) queryParams += key + '=' + args[key] + '&'
    })
    return ApiService.get(`${defaultPath}/${queryParams}`)
  },

  retrieve: function(uuid) {
    if (!uuid) return
    return ApiService.get(`${defaultPath}/${uuid}`)
  },

  create: function(model) {
    return ApiService.post(`${defaultPath}`, model)
  },

  update: function(uuid, model) {
    if (!uuid || !model) return
    return ApiService.patch(`${defaultPath}/${uuid}`, model)
  },

  delete: function(uuid) {
    if (!uuid) return
    return ApiService.delete(`${defaultPath}/${uuid}`)
  },

  dupe: function(uuid) {
    if (!uuid) return
    return ApiService.post(`${defaultPath}/${uuid}`, {})
  }
}

export default formsService
export { formsService }
