<template>
  <router-view />
</template>

<style lang="scss">
.dp__theme_dark {
  --dp-background-color: #232a3b;
  --dp-success-color: #00b894;
  --dp-primary-color: #0984e3;
}
.dp__theme_light {
  --dp-background-color: #f5f6fa;
  --dp-success-color: #00b894;
  --dp-primary-color: #0984e3;
}
</style>
